import {UserPanelReviews} from "../user-panel/user-panel-reviews";

export class UserPanel {
    app: app.App
    userPanelReviews: UserPanelReviews

    constructor(app: app.App) {
        this.app = app
        this.userPanelReviews = new UserPanelReviews(app)
    }

}

