export function scrollByDrag(elem: HTMLElement) {
    if (!elem) return

    elem.style.cursor = 'grab';

    let clickTime: number;
    let pos = { top: 0, left: 0, x: 0, y: 0 };
    let posX = 0;
    let dxResult: number;

    const mouseDownHandler = function (e: MouseEvent) {
        elem.style.cursor = 'grabbing';
        elem.style.userSelect = 'none';
        clickTime = Date.now();

        pos = {
            left: elem.scrollLeft,
            top: elem.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e: MouseEvent) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        elem.scrollTop = pos.top - dy;
        elem.scrollLeft = pos.left - dx;
        posX = elem.scrollLeft

        dxResult = dx
        addBefore()
        addAfter()
    };

    const mouseUpHandler = function (e: MouseEvent) {
        elem.style.cursor = 'grab';
        elem.style.removeProperty('user-select');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    const touchMove = function (e: TouchEvent) {
        posX = elem.scrollLeft
        addBefore()
        addAfter()
    }

    const onClick = function (e: MouseEvent) {
        if (Date.now() - clickTime > 500 || Math.abs(dxResult) > 10) {
            e.stopPropagation()
            e.preventDefault()
        }
        dxResult = 0
    }

    const addListeners = function () {
        if (elem.scrollWidth - elem.clientWidth > 0) {
            elem.addEventListener('mousedown', mouseDownHandler);
            elem.addEventListener('touchmove', touchMove);
            elem.addEventListener('click', onClick);
            addAfter()
        }
    }

    const removeListeners = function () {
        elem.removeEventListener('mousedown', mouseDownHandler);
        elem.removeEventListener('touchmove', touchMove);
        elem.removeEventListener('click', onClick);
        addAfter()
        posX = 0
    }

    addEventListener('resize', (event) => {
        removeListeners()
        addListeners()
    });

    // Attach the handler
    addListeners()

    function addBefore() {
        if ($(elem).parent().find('.shadow-before').length === 0) {
            $(elem).before("<div class='shadow-before'></div>")
        }
        const shadowBefore = $(elem).parent().find('.shadow-before')
        shadowBefore.css({
            background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgb(240, 240, 240) 100%)",
            left: 0,
            top: 0,
            width: (posX > 30 ? 30 : posX) + "px",
            bottom: 0,
            content: "",
            position: "absolute",
            pointerEvents: "none",
            zIndex: 1,
        })
    }

    function addAfter() {
        if ($(elem).parent().find('.shadow-after').length === 0) {
            $(elem).after("<div class='shadow-after'></div>")
        }
        const shadowAfter = $(elem).parent().find('.shadow-after')
        const availableScrollAll = elem.scrollWidth - elem.clientWidth
        const width = availableScrollAll - posX > 30 ? 30 : availableScrollAll - posX
        shadowAfter.css({
            background: "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(240, 240, 240) 100%)",
            right: 0,
            top: 0,
            width: width + "px",
            bottom: 0,
            content: "",
            position: "absolute",
            pointerEvents: "none",
            zIndex: 1,
        })
    }
}


