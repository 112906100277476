import {ReviewData, ReviewSave} from "../modal/modal-review";

export class ModalReviewHtml {
    private app: app.App
    private modalElement: HTMLDivElement;
    private cancelButton: HTMLAnchorElement;
    private ratingElement: JQuery<HTMLElement>;
    private ratingSwitchButton: JQuery<HTMLElement>;
    private ratingElements: JQuery<HTMLElement>;
    private titleElement: JQuery<HTMLElement>;
    private checkboxPhotoApprove: JQuery<HTMLElement>;
    private nameInput: JQuery<HTMLElement>;
    private messageTextarea: JQuery<HTMLElement>;
    private submitButton: JQuery<HTMLElement>;
    private callback: Function | undefined;
    private dataCallback: ReviewSave | undefined;

    constructor(app: app.App) {
        this.app = app
        this.modalElement = this.createModalElement();
        this.cancelButton = this.modalElement.querySelector('.mfp-close') as HTMLAnchorElement;
        this.cancelButton.addEventListener('click', this.handleClose.bind(this));
        this.ratingElement = $(this.modalElement).find('.review-rating-star');
        this.ratingSwitchButton = $(this.modalElement).find('.review-switch-button');
        this.ratingSwitchButton.on('click', function() {
            $(this).toggleClass('active');
            $(this).parent().next().slideToggle();
            if (!$(this).hasClass('active')) {
                $('input[name="rating"]').prop('checked', false);
                $('.review-rating-star').removeClass('active');
            }
        });
        this.ratingElements = $(this.modalElement).find('.review-popup-rating-elements');
        this.titleElement = $(this.modalElement).find('.modal-title');
        this.checkboxPhotoApprove = $(this.modalElement).find('input[name="photo_approve"]');
        this.nameInput = $(this.modalElement).find('input[name="name"]');
        this.messageTextarea = $(this.modalElement).find('textarea[name="message"]');
        this.submitButton = $(this.modalElement).find('input[type="submit"]');
        this.submitButton.on('click', this.handleSubmit)

        this.handleRating()
    }

    private createModalElement(): HTMLDivElement {
        const modalElement = document.createElement('div');
        modalElement.className = 'modal modal-center modal-large modal-review';
        modalElement.style.display = 'none';

        modalElement.innerHTML = `
            <div class="modal-wrap">
                <div class="modal-content">
                    <div class="modal-content-wrap">
                        <h3 class="modal-title"></h3>	
                        <form>
                            <div class="review-popup-row">
                                <div class="review-popup-left">
                                    <div class="review-switch">
                                        <div class="review-switch-button">
                                            <div class="review-switch-button-inner"></div>
                                        </div>
                                        <div class="review-switch-text">Поставить оценку</div>
                                    </div>
                                    <div class="review-popup-rating-elements">
                                        <div class="review-popup-rating-element">
                                            <label class="review-rating-label">Оценка:</label>
                                            <div class="review-rating popup-review-rating">
                                                <label class="review-rating-star">
                                                    <input type="radio" name="rating" class="rating-5" value="5">
                                                </label>
                                                <label class="review-rating-star">
                                                    <input type="radio" name="rating" class="rating-4" value="4">
                                                </label>
                                                <label class="review-rating-star">
                                                    <input type="radio" name="rating" class="rating-3" value="3">
                                                </label>
                                                <label class="review-rating-star">
                                                    <input type="radio" name="rating" class="rating-2" value="2">
                                                </label>
                                                <label class="review-rating-star">
                                                    <input type="radio" name="rating" class="rating-1" value="1">
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-popup-checkboxes">
                                        <label class="review-popup-checkbox">
                                            <span class="text">Фото соответствует</span>
                                            <div class="jq-checkbox-own">
                                                <input type="checkbox" name="photo_approve" value="1">
                                                <div class="jq-checkbox__div-own"></div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="review-popup-text">Запрещены оскорбления и мат.</div>
                                </div>
                                <div class="review-popup-right">
                                    <div>
                                        <label for="guestName">Имя</label>
                                        <input id="guestName" type="text" name="name">
                                    </div>
                
                                    <div>
                                        <label for="reviewText">Ваше сообщение</label>
                                        <textarea id="reviewText" name="message" required=""></textarea>
                                        <p class="error hidden">Нужно добавить сообщение к отзыву</p>
                                    </div>
                                </div>
                            </div>
                            <div class="review-popup-submit">
                                <input type="submit" value="Отправить" class="main-button">
                            </div>
                        </form>		
                        <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                    </div>
                </div>
            </div>
        `;
        let main = document.body.querySelector('.main.page')
        if (main) {
            main.appendChild(modalElement);
        }

        return modalElement;
    }

    private handleClose(event: Event) {
        event.preventDefault();
        this.modalElement.remove()
        this.app.modal?.closeOverlay()
    }

    private handleRating() {
        let $this = this
        $(this.ratingElement).on('click', function() {
            $($this.ratingElement).removeClass('active');
            $(this).addClass('active');
            $(this).nextAll().addClass('active');
        });
    }

    handleSubmit = (e: Event) => {
        e.preventDefault()
        let formData = $(this.modalElement).find('form').serialize()
        if (!this.checkFormError()) {
            return
        }
        if (this.callback && this.dataCallback) {
            this.dataCallback.formData = formData
            this.callback(this.dataCallback);
        }
    }
    public checkFormError() {
        let message = this.messageTextarea.val()
        if (typeof message === "string") {
            if (message.length === 0) {
                this.messageTextarea.next().removeClass('hidden')
                return false
            } else {
                this.messageTextarea.next().addClass('hidden')
            }
        }
        return true
    }

    public changeTitle(title: string) {
        this.titleElement.text(title)
    }

    public showEdit(reviewData: ReviewData, callback: Function, dataCallback: ReviewSave) {
        console.log('reviewData', reviewData)
        if (reviewData.rating === 0) {
            this.ratingElements.hide()
        } else {
            this.ratingSwitchButton.addClass('active')
            $(this.ratingElement).removeClass('active');
            $(this.ratingElement).find('.rating-'+reviewData.rating).prop('checked', true)
            $(this.ratingElement).each((index, element) => {
                let countSkip = 5 - reviewData.rating
                if (index >= countSkip) {
                    $(element).addClass('active')
                }
            })
        }
        if (reviewData.photoApprove) {
            this.checkboxPhotoApprove.prop("checked", true)
        }
        this.nameInput.val(reviewData.name)
        this.messageTextarea.val(reviewData.message)

        this.modalElement.style.display = 'block';
        this.callback = callback;
        this.dataCallback = dataCallback;
        this.app.modal?.showModal("review")
    }

    public show(callback: Function, dataCallback: ReviewSave) {
        this.ratingElements.hide()
        this.modalElement.style.display = 'block';
        this.callback = callback;
        this.dataCallback = dataCallback;
        this.app.modal?.showModal("review")
    }
}

