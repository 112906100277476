import { lengthObject } from '../functions';

export class Placeholder {
    app: app.App
    el: JQuery<HTMLElement> | undefined
    elFilters:JQuery<HTMLElement> | undefined
    elFiltersHeader:JQuery<HTMLElement> | undefined
    elFiltersActives:JQuery<HTMLElement> | undefined
    elFiltersMobile:JQuery<HTMLElement> | undefined
    elFiltersDesktop:JQuery<HTMLElement> | undefined

    constructor(app: app.App) {
        this.app = app
        if (!this.app.isCopy) {
            this.init();
        }
    }

    init = () => {
        this.el = $('.wrapper .filters-footer')
        this.elFiltersHeader = $('.wrapper .filters-header')
        this.elFilters = this.elFiltersHeader.find('.filters')
        this.elFiltersActives = this.elFiltersHeader.find('.active-filters')
        this.elFiltersMobile = this.elFiltersHeader.find('.filters.mobile  .filters')
        this.elFiltersDesktop = this.elFiltersHeader.find('.filters.desktop')
        this.addListeners()
    }

    addFilter = (filterName:string, textFilter:string, textPlaceholder: string, id:number|any[], updateCatalog: boolean, isRange: Enums.IsRange) => {
        if (filterName === 'distance' && typeof id === 'number') {
            this.app.catalog?.setDistance(id)
        }
        this.addFilterMobile(filterName, textFilter, id)
        this.addFilterDesktop(filterName, textPlaceholder, id, isRange)
        if (updateCatalog) {
            this.app.catalog?.setPage(1)
            this.app.catalog?.catalogAjax(false, false, true)
        }
    }

    addFilterMobile = (filterName:string, text:string, id:number|string|any[]) => {
        if (Array.isArray(id)) {
            id = id.toString()
        }
        const filter = new PlaceholderFilter().createMobile(filterName, text, id)
        this.app.catalog?.formFilters.addFilter(filterName, id)
        this.updateCountFilter(filterName, filter.html)
    }

    addFilterDesktop = (filterName:string, text:string, id:number|string|any[], isRange: Enums.IsRange) => {
        if (filterName === 'categories') {
            return
        }
        this.replaceFilters(filterName, isRange);
        const filter = new PlaceholderFilter().createDesktop(filterName, text, id, isRange)
        if (isRange.name !== "null") {
            let partElem = this.elFiltersDesktop?.find('[data-filter-name="'+filterName+'"]')
            if (partElem && partElem.length > 0) {
                partElem.after(filter.html)
                partElem.remove()
            } else {
                this.elFiltersDesktop?.append(filter.html)
            }
        } else {
            this.elFiltersDesktop?.append(filter.html)
        }
    }

    replaceFilters = (filterName: string, isRange: Enums.IsRange) => {
        if (filterName === 'ages') {
            this.elFiltersDesktop?.find('[data-filter-name="ages_from_to"]').remove()
            this.elFiltersMobile?.find('[data-filter-name="ages_from_to"]').remove()
        }
        if (isRange.name === 'fix') {
            this.elFiltersDesktop?.find('[data-filter-name="'+filterName+'"]').remove()
        }
    }

    removeFilterDesktop = (filterName:string, id:number|any[], isRange: Enums.IsRange) => {
        if (Array.isArray(id)) {
            this.elFiltersDesktop?.find(`span[data-filter-name="${filterName}"]`).remove()
            if (isRange.name === 'to' && id[0] !== -1) {
                this.app.filters.setUnsetBySelect($('select[data-name="'+filterName+'"][data-range-from]'), false);
            } else if (isRange.name === 'from' && id[1] !== -1) {
                this.app.filters.setUnsetBySelect($('select[data-name="'+filterName+'"][data-range-to]'), false);
            }
        } else {
            this.elFiltersDesktop?.find(`span[data-filter-name="${filterName}"][data-value-id="${id}"]`).remove()
        }
    }

    addFilterRemoveAll = (count:number) => {
        const countElem = this.elFiltersDesktop?.find('[data-filter-name="all-filters"] span')
        if (countElem && countElem.length > 0) {
            countElem.text(`( ${count} )`)
        } else {
            const filter = new PlaceholderFilter().createAllFilters(1)
            this.elFiltersDesktop?.append(filter.html)
        }
    }

    removeAllFilters = () => {
        this.elFiltersDesktop?.find(`span[data-filter-name]`).remove()
        this.elFiltersMobile?.find(`span[data-filter-name]`).remove()
        this.app.catalog?.formFilters.removeFilters('all-filters', false)

        // close FiltersHeader
        if (!this.app.filters?.elGroupBody?.is(':visible')) {
            this.app.filters?.elGroupHeader?.slideToggle(100).toggleClass('opened');
        }
    }

    removeFilter = (filterName:string, id:number|any[]|null, isRange: Enums.IsRange) => {
        if (filterName === 'distances') {
            this.app.catalog?.setDistance(-1)
        }
        if (id) {
            this.app.catalog?.formFilters.removeFilter(filterName, id)
            this.removeFilterDesktop(filterName, id, isRange)
        } else {
            this.app.catalog?.formFilters.removeFilters(filterName, false)
        }
        if (filterName !== 'categories') {
            this.updateCountFilter(filterName);
        }
        this.app.catalog?.setPage(1)
        this.app.catalog?.catalogAjax(false, false, true)
    }

    updateCountFilter = (filterName:string, filterHtml = "") => {
        const count = this.app.catalog?.formFilters.filters[filterName]?.length

        if (filterName === 'categories') {
            return
        }
        if (this.app.catalog?.formFilters) {
            const countFilters = this.app.catalog?.formFilters.getCountActiveFilters();
            this.app.catalog?.form.formTopFilters.setCountFilters(countFilters);
            if (countFilters > 0) {
                this.addFilterRemoveAll(countFilters)
            } else {
                this.removeAllFilters()
            }
            if (count) {
                if (count >= 1) {
                    const filter = this.elFiltersMobile?.find(`span[data-filter-name="${filterName}"] span.counter`)
                    if (filter && filter.length > 0) {
                        filter.text(count)
                    } else {
                        if (filterName !== 'categories') {
                            this.elFiltersMobile?.append(filterHtml);
                        }
                    }
                }
            } else {
                this.elFiltersMobile?.find(`span[data-filter-name="${filterName}"]`).remove()
            }
        }
    }

    addListeners = () => {
        console.log('placeholder addListeners')
        this.elFilters?.on('click', (e) => {
            console.log(' placeholder click')
            if (e.target.matches('i')) {
                const dataRemoveFilter = $(e.target).parent().data()
                const dataValueId = dataRemoveFilter.valueId
                const dataFilterName = dataRemoveFilter.filterName
                const daraRange = dataRemoveFilter.range ? {name: dataRemoveFilter.range} : {name: 'null'}
                if (dataFilterName === 'distance' || dataFilterName === 'all-filters') {
                    this.app.catalog?.setDistance(-1)
                    this.app.catalog?.setPage(1)
                }
                if (dataValueId) {
                    if (daraRange.name !== 'null') {
                        this.app.filters.unsetSelectByRange(dataFilterName, daraRange)
                        this.app.catalog?.catalogAjax(false, false, true)
                    } else {
                        this.removeFilter(dataFilterName, dataValueId, daraRange)
                    }
                    this.app.filters.setCheckedFilter(dataFilterName, dataValueId, false)
                } else {
                    if (dataFilterName === 'all-filters') {
                        this.removeAllFilters()
                        this.app.filters.uncheckedFilters.uncheckedAllFiltersWithoutCategories()
                        this.app.catalog?.form.formTopFilters.setCountFilters(0)
                        this.app.catalog?.setPage(1)
                        this.app.catalog?.catalogAjax(false, false, true)
                    } else {
                        this.removeFilter(dataFilterName, null, {name: "null"})
                        this.app.filters.uncheckedFilters.uncheckedFiltersByName(dataFilterName)
                    }
                }
            }
        })
        this.setScrollFilters()
    }


    changeHtmlPlaceholder = (html: string) => {
        this.el?.html(html)
    }
    changeHtmlActiveFilters = (html: string) => {
        this.elFiltersActives?.html(html)
    }

    setScrollFilters = () => {
        this.app.services.scrollByDrag(this.elFiltersMobile ? this.elFiltersMobile?.find('.filters-scroll')[0] : null)
    }
}

class PlaceholderFilter {
    html:string = ""
    filterName:string = ""
    text:string = ""
    id:string|number|any[] = ""

    createMobile = (filterName:string, text:string, id:string|number) => {
        this.filterName = filterName
        this.text = text
        this.id = id
        this.html = `<span data-filter-name="${filterName}"><span class="counter">1</span>${text}<i class="icon-circle"></i></span>`
        return this
    }

    createDesktop = (filterName:string, text:string, id:string|number|any[], isRange: Enums.IsRange) => {
        this.filterName = filterName
        this.text = text
        this.id = id

        if (Array.isArray(id)) {
            id = isRange.name === 'null' ? id.toString() : (isRange.name === 'from' ? id[0] : id[1])
        }

        const addDataRange = isRange.name !== 'null' ? ` data-range="${isRange.name}"` : ""

        this.html = `<span data-filter-name="${filterName}" data-value-id="${id}" ${addDataRange}>${text}<i class="icon-circle"></i></span>`
        return this
    }

    getDataRange = (): {rangeType: string|null, from: number|null, to: number|null} => {
        if (Array.isArray(this.id)) {
            const rangeType = this.id[0] === this.id[1] ? "fix" : (this.id[0] === -1 ? "to" : "from")
            return {
                rangeType: rangeType,
                from: this.id[0],
                to: this.id[1]
            }
        } else {
            return {
                rangeType: null,
                from: null,
                to: null
            }
        }
    }

    createAllFilters = (count:number) => {
        this.html = `<span data-filter-name="all-filters">Удалить все <span class="counter">( ${count} )</span><i class="icon-circle"></i></span>`
        return this
    }
}
