export function getParameterByName(name: string, url = window.location.href): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getOffset( el: HTMLElement| undefined ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        // @ts-ignore
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

export function getCookie(name:string){
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
}

export function setCookie(name:string, value: string, options: any) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    console.log('updatedCookie', updatedCookie)
    document.cookie = updatedCookie;
}

export function deleteCookie( name: string, path: string, domain: string | null  = null ) {
    if( getCookie( name ) ) {
        document.cookie = name + "=" +
            ((path) ? ";path="+path:"")+
            ((domain)?";domain="+domain:"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export function arrayRemoveKey(arr: any[], value: any) {
    return arr.filter(function(ele){
        return ele != value;
    });
}

export function getKeyFromSearch(key: string) {
    const query = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(<string>prop),
    });
    // @ts-ignore
    return query[key]
}

export function lengthObject(object: object|undefined) {
    var count = 0;
    var i;

    if (object) {
        for (i in object) {
            if (object.hasOwnProperty(i)) {
                count++;
            }
        }
    }
    return count
}


export function getCookieAuth(name: string) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
export function getAjaxHeader() {

    let apiToken = getCookieAuth('api_token');
    return {
        'Authorization': 'Bearer ' + apiToken
    }
}
