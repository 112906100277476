import { Filters } from './filters';

export class UncheckedFilters {
    app: app.App
    el: app.FiltersEls
    elModal: app.FilterModalEls
    elSubstrate: app.FilterSubstrateEls
    elGroup: JQuery<HTMLElement> | undefined
    queries: app.FiltersQueries

    constructor(app: app.App, filters: Filters) {
        this.app = app
        this.el = filters.el
        this.elModal = filters.elModal
        this.elSubstrate = filters.elSubstrate
        this.elGroup = filters.elGroup
        this.queries = filters.queries
    }

    uncheckedAllFiltersWithCategories = () => {
        const inputs = this.el.basicFilterListsLi?.parents(`ul[data-name]`).find(`input:checked`)
        const selects = this.el.basicFilterListsSelect?.filter(`.checked`)
        this.uncheckedFiltersByElems(inputs, selects)
    }

    uncheckedAllFiltersWithoutCategories = () => {
        const inputs = this.el.basicFilterListsLi?.parents(`ul[data-name!="categories"]`).find(`input:checked`)
        const selects = this.el.basicFilterListsSelect?.filter(`.checked`)
        this.uncheckedFiltersByElems(inputs, selects)
    }

    uncheckedFiltersByName = (filterName: string) => {
        const inputs = this.el.basicFilterListsLi?.parents(`ul[data-name="${filterName}"]`).find(`input:checked`)
        const selects = this.el.basicFilterListsSelect?.filter(`.checked[data-name="${filterName}"]`)
        this.uncheckedFiltersByElems(inputs, selects)
    }

    uncheckedFiltersByElems = (inputs: JQuery<HTMLElement>|undefined, selects: JQuery<HTMLElement>|undefined) => {
        inputs?.each((index, el) => {
            $(el).prop( "checked", false )
            $(el).parent().removeClass('checked')
            if ($(el).closest('li').data().single !== undefined) {
                $(el).closest('.catalog-filter__scroll').find('select, input').each((index, elFilter) => {
                    $(elFilter).prop('disabled', '').val(-1)
                    $(elFilter).closest('li').removeClass('disabled_checkbox')
                })
            }
            let textChecked = this.liCheckedText($(el))
            this.setCountCheckedList($(el), textChecked[0], textChecked[1])
        })
        selects?.each((index, el) => {
            $(el).val( -1)
            $(el).removeClass('checked')
            let textChecked = this.liCheckedText($(el))
            this.setCountCheckedList($(el), textChecked[0], textChecked[1])
        })
    }

    resetAllFilters = (resetDistance: boolean, resetSubCategories: boolean) => {
        console.log('resetAllFilters resetDistance', resetDistance )
        if (resetDistance) {
            this.app.catalog?.form.formTopFilters?.setCurrentRadius(-1)
            this.app.catalog?.setDistance(-1)
            this.app.catalog?.setPage(1)
        }
        this.app.catalog?.placeholder.elFiltersMobile?.find(`span[data-filter-name]`).remove()
        this.app.catalog?.formFilters.removeFilters('all-filters', resetSubCategories)
        if (resetSubCategories) {
            this.uncheckedAllFiltersWithCategories()
        } else {
            this.uncheckedAllFiltersWithoutCategories()
        }

        const countFilters = this.app.catalog?.formFilters.getCountActiveFilters()
        this.app.catalog?.form.formTopFilters.setCountFilters(countFilters ?? 0)
    }

    liCheckedText = (e: JQuery): [number, string] => {
        let checkedText = ""
        let checked = e.parents('.catalog-filter__dropdown, ul[data-name="categories"]').find('input:checked')
            .parents('label').find('.label-text')
        checked.each((index, e) => {
            checkedText += (index === 0 ? "" : ", ") + $(e).text()
        })
        console.log('liCheckedText', [checked.length, checkedText])
        return [checked.length, checkedText]
    }

    setCountCheckedList = (e: JQuery, length: number, text: string) => {
        let isTopFilter = false
        let buttonText = e.closest('.filter-cell')
        let isSubCategories = false
        if (buttonText.length === 0) {
            buttonText = e.closest('.top-filter')
            if (buttonText.hasClass('filter-sub-categories')) {
                isSubCategories = true
            }
            isTopFilter = true
        }
        console.log('setCountCheckedList', isSubCategories)

        let textFilters = length ? ": " + text : ""
        if (isSubCategories && length === 0) {
            textFilters = ": все"
        }

        const spanActive = buttonText.find(this.queries.basicFilterActive)
            .text(textFilters)
        const textCount = isTopFilter ? length : "(" + length + ")"
        const spanCount = buttonText.find(this.queries.basicFilterCounter)
            .text(length > 0 ? textCount : "")
        if (length) {
            buttonText.addClass('selected')
            spanCount.addClass('active')
        } else {
            buttonText.removeClass('selected')
            spanCount.removeClass('active')
        }
    }
}

