import {ModalConfirmHtml} from "../modal/modal-confirm-html";

export class ModalConfirm {
    app: app.App

    constructor(app: app.App) {
        this.app = app
    }

    show = (message: string, callback: Function, data: object) => {
        let modal = new ModalConfirmHtml(this.app)
        modal.show(message, callback, data)
    }

}

