import {getAjaxHeader} from "../functions";
import {ModalReview, ReviewData, ReviewSave} from "../modal/modal-review";

export class UserPanelReviews {
    app: app.App

    main: JQuery<HTMLElement>

    constructor(app: app.App) {
        this.app = app
        this.main = $('main.user-panel.reviews')
        this.addListeners()
    }


    addListeners = () => {
        this.main.find('.remove-review').on('click', (e) => {
            e.preventDefault()
            let title = e.currentTarget.dataset.title
            let id = e.currentTarget.dataset.id
            if (id) {
                this.app.modalConfirm.show("Удалить отзыв на " + title + "?", this.app.reviews.remove, {id});
                console.log('remove', e.currentTarget.dataset.id)
            }
        })
        this.main.find('.edit-review').on('click', (e) => {
            e.preventDefault()
            console.log(' e.currentTarget.dataset',  e.currentTarget.dataset)
            let reviewData: ReviewData = {
                reviewId: parseInt(e.currentTarget.dataset.reviewId ?? ""),
                worksheetId: parseInt(e.currentTarget.dataset.worksheetId ?? ""),
                worksheetName: e.currentTarget.dataset.worksheetTitle ?? "",
                rating: parseInt(e.currentTarget.dataset.rating ?? "0"),
                photoApprove: parseInt(e.currentTarget.dataset.photoApprove ?? "0") === 1,
                name: e.currentTarget.dataset.name ?? "",
                message: e.currentTarget.dataset.message ?? "",
            }
            let modalReview = new ModalReview(this.app)
            modalReview.showEdit(reviewData, this.app.reviews.update, <ReviewSave>{reviewId: reviewData.reviewId, worksheetId: reviewData.worksheetId, formData: ""})
        })
    }

}

