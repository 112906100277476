export class FormTopFilters {
    app: app.App
    $this: any

    container: JQuery<HTMLElement> | undefined
    linksCities: JQuery<HTMLElement> | undefined
    elRadius:JQuery<HTMLElement> | undefined
    elRadiusDropDown:JQuery<HTMLElement> | undefined
    elToggleFilters:JQuery<HTMLElement> | undefined
    elResetAllFilters:JQuery<HTMLElement> | undefined

    private _currentRadius: number = -1;

    constructor(app: app.App, $this: any) {
        console.log('top filters constructor')
        this.app = app
        this.$this = $this
        this.refreshElements()
        this.addListeners()
    }

    refreshElements = () => {
        this.container = $(this.$this).find('.top-filters')
        this.linksCities = this.container?.find('li[data-region-link]')
        this.elRadius = this.container?.find('.radius')
        this.elRadiusDropDown = this.container?.find('.radius-dropdown')
        this.elToggleFilters = this.container?.find('.toggle-filters .toggle-button')
        this.elResetAllFilters = this.container?.find('.reset-filters')
    }

    get currentRadius(): number {
        return this._currentRadius;
    }
    setCurrentRadius(radius: number) {
        this._currentRadius = radius
    }

    addListeners = () => {
        this.elRadiusDropDown?.on('click', (el) => {
            if ($(el.target).hasClass('select')) {
                const radius = $(el.target).data().radius
                if (parseInt(radius) !== this.currentRadius) {
                    this.setRadius(parseInt(radius))
                    this.app.catalog?.catalogAjax(false)
                }
            }
        })
        this.elToggleFilters?.on('click', (el) => {
            this.app.filters?.toggleFiltersGroup()
            this.toggleOpenedButtonFilter()
        })
        this.elResetAllFilters?.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.app.filters?.uncheckedFilters.resetAllFilters(true, false)
            this.app.catalog?.catalogAjax(false, false, true)
        })
    }

    changeOpenedButtonFilter = (isOpened: boolean) => {
        if (isOpened) {
            this.elToggleFilters?.addClass('opened')
        } else {
            this.elToggleFilters?.removeClass('opened')
        }
    }
    toggleOpenedButtonFilter = () => {
        this.elToggleFilters?.toggleClass('opened')
    }

    closeOpenedDropDowns = (e:  MouseEvent|null) => {
        if (e) {
            // @ts-ignore
            if (this.elRadius?.has(e?.target).length === 0) {
                if (this.elRadiusDropDown?.hasClass('active')) {
                    this.elRadiusDropDown?.toggleClass('active')
                }
            }
        } else {
            if (this.elRadiusDropDown?.hasClass('active')) {
                this.elRadiusDropDown?.toggleClass('active')
            }
        }
    }

    setCountFilters = (count: number) => {
        const elCounter = this.elToggleFilters?.find('.text .count')
        if (elCounter && elCounter.length === 1) {
            elCounter.text(count)
            const elGroup = elCounter.parents('.select-group')
            if (count === 0) {
                elCounter.removeClass('active')
                elGroup.removeClass('show-reset')
            } else {
                elCounter.addClass('active')
                elGroup.addClass('show-reset')
            }
        }
    }

    setRadius = (radius: number) => {
        console.log('setRadius', radius,this._currentRadius)
        this.selectRadius(radius, false)
        if (radius < this._currentRadius) {
            this.app.catalog?.setPage(1)
        }
        this._currentRadius = radius
    }

    selectRadius = (radius: number, disabled: boolean) => {
        if (disabled) {
            radius = 0
        }
        this.updateAddedRadius(radius)
        const activeSelectRadius = this.elRadiusDropDown?.find('.select.active').first()
        activeSelectRadius?.removeClass('active')
        this.elRadiusDropDown?.find('.select[data-radius="'+radius+'"]').addClass('active')
        const textRadius = radius > 0 ? "+" + radius : radius
        this.elRadius?.find('.normal-text').text(textRadius + " km")
        if (disabled) {
            this.elRadius?.addClass('disabled')
        } else {
            this.elRadius?.removeClass('disabled')
        }
    }

    updateAddedRadius = (radius: number) => {
        if (this.elRadiusDropDown?.length === 0) return;
        const lastDefaultRadius = this.elRadiusDropDown?.find('.select:not(.added)').last().data().radius
        if (radius <= lastDefaultRadius) {
            return
        }
        this.elRadiusDropDown?.find('.select.added').remove()
        this.elRadiusDropDown?.append('<div class="select added" data-radius="'+radius+'">+'+radius+' km</div>')
    }
}
