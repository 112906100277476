
export class HistoryRegion {
    lat: number|undefined
    long: number|undefined
    cityName: string|undefined
    serviceId: number|undefined

    createByDataRequest = (geolocation: Cities.AjaxCatalog, serviceId: number) => {
        this.lat = geolocation.lat
        this.long = geolocation.long
        this.cityName = geolocation.cityName
        this.serviceId = serviceId
        return this
    }

    createByState = (state: HistoryRegion) => {
        this.lat = state.lat
        this.long = state.long
        this.cityName = state.cityName
        this.serviceId = state.serviceId
        return this
    }

    getState = () => {
        return {
            lat: this.lat,
            long: this.long,
            cityName: this.cityName,
            serviceId: this.serviceId,
        }
    }

    addToHistory = (page: string) => {
        const historyData = this.getState()
        console.log(`window pushstate HistoryRegion: ${window.location.href}, historyData: ,`, historyData);
        window.history.pushState(historyData, '', page)
    }

    instanceOfHistoryRegion = (object: any): object is HistoryRegion => {
        return 'lat' in object;
    }

    isNull = () => {
        return !this.lat && !this.long && !this.cityName && !this.serviceId
    }

}
