export class Header {
    app: app.App

    elHeader:JQuery<HTMLElement> | undefined
    elMenu:JQuery<HTMLElement> | undefined
    elModalMenu:JQuery<HTMLElement> | undefined

    headerQuery = {
        header: '.header-container',
        menu: '.header-nav__list, .nav-mobile__list',
        modalMenu: '.nav-mobile',
    }

    constructor(app: app.App) {
        this.app = app
        this.refreshElements()
        this.addListeners()
    }

    refreshElements = () => {
        this.elHeader = $(this.headerQuery.header)
        this.elMenu = this.elHeader.find(this.headerQuery.menu)
        this.elModalMenu = this.elHeader.find(this.headerQuery.modalMenu)
    }

    addListeners = () => {

    }

    changeMenuHtml = (html: string) => {
        this.elMenu?.each((index, el) => {
            $(el).html(html)
        })
    }

    closeModalMenu = () => {
        if (this.elModalMenu?.hasClass('opened')) {
            this.elModalMenu.toggleClass('opened')
            $('.overlay').removeClass('opened')
        }
    }
}
