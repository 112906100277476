import {getAjaxHeader} from "../functions";
import {ModalReview, ReviewData, ReviewSave} from "../modal/modal-review";
import {UserPanelReviews} from "@/js/user-panel/user-panel-reviews";

export class Reviews {
    app: app.App
    elQuery = {
        reviewAdd: '.reviews-add-button',
        reviewEdit: '.edit-review',
        reviewRemove: '.remove-review',
        reviewModal: '#review-popup',
    }

    constructor(app: app.App) {
        this.app = app
        this.addListeners()
    }

    addListeners = () => {
        $(this.elQuery.reviewAdd).on('click', (e) => {
            e.preventDefault()
            let data = $(e.currentTarget).data()
            let modalReview = new ModalReview(this.app)
            let name = $('.page__title.product-name').first().text()
            modalReview.show(name, this.addReview, <ReviewSave>{reviewId: null, worksheetId: data.id, formData: ""})
        })
        $(this.elQuery.reviewEdit).on('click', (e) => {
            e.preventDefault()
            let data = $(e.currentTarget).data()

            let reviewData: ReviewData = {
                reviewId: parseInt(data.reviewId ?? ""),
                worksheetId: parseInt(data.worksheetId ?? ""),
                worksheetName: $('.page__title.product-name').first().text(),
                rating: parseInt(data.rating ?? "0"),
                photoApprove: parseInt(data.photoApprove ?? "0") === 1,
                name: data.name ?? "",
                message: data.message ?? "",
            }
            let modalReview = new ModalReview(this.app)
            modalReview.showEdit(reviewData, this.update, <ReviewSave>{reviewId: reviewData.reviewId, worksheetId: reviewData.worksheetId, formData: ""})
        })
        $(this.elQuery.reviewRemove).on('click', (e) => {
            e.preventDefault()
            let data = $(e.currentTarget).data()
            let name = $('.page__title.product-name').first().text()
            this.app.modalConfirm.show("Удалить отзыв на " + name + "?", this.remove, {id:data.id});
        })
    }

    addReview = async (data: ReviewSave) => {
        return new Promise<void>((resolve, reject) => {
            let $this = this

            $.ajax({
                url: "/api/review/add/" + data.worksheetId,
                type: 'POST',
                headers: getAjaxHeader(), // Добавляем заголовок
                data: data.formData,
                success: function(response) {
                    console.log('form add review success', response)
                    location.reload()
                },
                error: function(xhr, status, error) {
                    if (xhr.status === 401) {
                        $this.app.modal?.addModal({name: "noAuth"}, 99)
                    }
                }
            });
        })
    }
    update = (data: ReviewSave) => {
        console.log("обновили", data);
        let $this = this

        $.ajax({
            url: "/api/review/update/" + data.reviewId,
            type: 'POST',
            headers: getAjaxHeader(), // Добавляем заголовок
            data: data.formData,
            success: function(response) {
                console.log('update review', response)
                location.reload()
            },
            error: function(xhr, status, error) {
                $this.app.modal?.addModal({name: "error"}, 180, "Не смогли обновить отзыв")
            }
        });
    }

    remove = (data: any) => {
        console.log("удалили", data.id );
        let $this = this

        $.ajax({
            url: "/api/review/remove/" + data.id,
            type: 'POST',
            headers: getAjaxHeader(), // Добавляем заголовок
            success: function(response) {
                console.log('remove review', response)
                location.reload()
            },
            error: function(xhr, status, error) {
                $this.app.modal?.addModal({name: "error"}, 180, "Не смогли удалить отзыв")
            }
        });
    }
}

