import { HistoryRegion } from '../history/historyRegion';
import {HistoryCatalog} from "@/js/history/historyCatalog";

export class CatalogCity {
    app: app.App

    elCatalogs:JQuery<HTMLElement> | undefined
    elHeaderWrapper:JQuery<HTMLElement> | undefined
    elForm:JQuery<HTMLElement> | undefined
    elImages:JQuery<HTMLElement> | undefined
    elCategoryName:JQuery<HTMLElement> | undefined
    elBreadcrumbs:JQuery<HTMLElement> | undefined
    elH1:JQuery<HTMLElement> | undefined
    elSeoText:JQuery<HTMLElement> | undefined
    elSeoFull:JQuery<HTMLElement> | undefined
    elSeoFollow:JQuery<HTMLElement> | undefined
    serviceId: number = 0

    catalogQuery = {
        catalog: '.catalog',
        headerWrapper: '.header-form > .wrapper',
        form: '.header-form .form .form-limit-width',
        images: '.img-wrapper img.loading',
        categoryName: '.select-category .text',
        breadcrumbs: '.header-form .breadcrumbs',
        h1: '.header-form .form h1',
        seoText: '.header-form .form .catalog-text-short',
        seoFull: '.seo-footer',
        seoFollow: '.page__title.follow',
    }

    constructor(app: app.App) {
        this.app = app
        this.refreshElements()
        if (this.app.isHomeOrCity()) {
            // this.sliderInit();
        }
    }

    refreshElements = () => {
        this.elCatalogs = $(this.catalogQuery.catalog)
        this.elHeaderWrapper = $(this.catalogQuery.headerWrapper)
        this.elForm = $(this.catalogQuery.form)
        this.elImages = this.elCatalogs.find(this.catalogQuery.images)
        this.elCategoryName = $(this.catalogQuery.categoryName)
        this.elBreadcrumbs = $(this.catalogQuery.breadcrumbs)
        this.elH1 = $(this.catalogQuery.h1)
        this.elSeoText = $(this.catalogQuery.seoText)
        this.elSeoFull = $(this.catalogQuery.seoFull)
        this.elSeoFollow = $(this.catalogQuery.seoFollow)
    }

    // sliderInit = () => {
    //     if (this.elCatalogs && this.elCatalogs.length > 0) {
    //         this.elCatalogs.find('.flex-container').each((i, el) => {
    //             const count =  $(el).data().count ?? 4
    //             $(el).slick({
    //                 // normal options...
    //                 touchThreshold: 10,
    //                 slidesToShow: count,
    //                 infinite: false,
    //                 swipeToSlide: true,
    //                 waitForAnimate: false,
    //                 speed:150,
    //                 // centerMode: true,
    //                 arrows: true,
    //                 prevArrow: "<button type='button' class='slick-arrow slick-prev'><div class='icon'>\n" +
    //                     "    <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>\n" +
    //                     "        <path d='M10.0833 1.25L5.99999 5.33333L1.91666 1.25' stroke='#C8C8C8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>\n" +
    //                     "    </svg>\n" +
    //                     "</div></button>",
    //                 nextArrow: "<button type='button' class='slick-arrow slick-next'><div class='icon'>\n" +
    //                     "    <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>\n" +
    //                     "        <path d='M10.0833 1.25L5.99999 5.33333L1.91666 1.25' stroke='#C8C8C8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>\n" +
    //                     "    </svg>\n" +
    //                     "</div></button>",
    //                 // the magic
    //                 responsive: [{
    //                     breakpoint: 1180,
    //                     settings: {
    //                         slidesToShow: 3,
    //                     }
    //                 }, {
    //                     breakpoint: 991,
    //                     settings: "unslick" // destroys slick
    //                 }]
    //             })
    //         })
    //     }
    // }

    getCityAndCatalog = async (dataRequest: Cities.AjaxCatalog, isReturnOnHistory: boolean) => {
        console.log('getCityAndCatalog', dataRequest)
        const link = "/api/city/get-city-and-catalog"
        const $this = this

        let data = {...dataRequest, ...{serviceId: this.serviceId}}
        return new Promise<string|null>((resolve, reject) => {
            $.post( link, data, function(data ) {
                if (data) {
                    const cityData: Dto.CityRegionDto = data.data.cityRegionDto
                    console.log('data.data', data.data)
                    console.log('cityData', cityData)
                    if(data.data.count === 0) {
                        $this.app.modal?.addModal({name: "notFoundWorksheets"}, 10, cityData.city.name_translate)
                        resolve("done")
                        return
                    }
                    $this.updateCatalog(data.data.catalog)
                    $this.updateBreadcrumbs(data.data.breadcrumbs)
                    $this.updateSeo(data.data.seo)
                    $this.updateSeoFooter(data.data.seoFooter)
                    $this.updateDebugThree(data.data.debugThree)
                    $this.app.header?.changeMenuHtml(data.data.headerMenu)

                    const city = <app.GeoCity> {
                        place_id: cityData.city?.place_id ?? cityData.region?.place_id,
                        name: cityData.city?.name ?? null,
                        name_translate: cityData.city?.name_translate ?? null,
                        parent_name: cityData.region.name,
                        parent_name_translate: cityData.region.name_translate,
                        cityId: cityData.region.id,
                        subCityId: cityData.city?.id ?? null,
                        lat: cityData.city?.lat ?? null,
                        long: cityData.city?.long ?? null,
                        country: cityData.city?.country ?? null,
                        parent_in_url: cityData.region.in_url
                    }
                    if (!isReturnOnHistory) {
                        new HistoryRegion().createByDataRequest(dataRequest, $this.serviceId ?? 0).addToHistory("/" + data.data.page)
                    }
                    $this.app.location?.setCityFromGeo(city)
                    $this.app.location?.changeCityInSelect(city.parent_name_translate ?? null, city.name_translate ?? null, city.parent_in_url ?? false, false)
                    $this.app.location?.selectCategoryRemoveDisabled()
                    console.log('getCityId city', city)
                    resolve("done")
                } else {
                    resolve("done")
                }
            }).fail(() => {
                this.app.modal?.addModal({name: "noCity"}, 3)
                resolve("done")
            })
        });
    }

    setHome = (isReturnOnHistory: boolean) => {
        const serviceId = 0
        const serviceName = ""

        const $this = this
        $.post( "/api/catalog-city/get", {serviceId, serviceName, page: "home"}, function( data ) {
            console.log('selectCategory data', data)
            if (data?.data) {
                $this.updateCatalog(data.data.catalog)
                $this.removeBreadcrumbs()
                $this.updateSeo(data.data.seo)
                $this.updateSeoFooter(data.data.seoFooter)
                $this.updateDebugThree(data.data.debugThree)
                $this.app.header?.changeMenuHtml(data.data.headerMenu)

                if (!isReturnOnHistory) {
                    new HistoryRegion().createByDataRequest(data, data.serviceId).addToHistory("/")
                }
            }
        })
    }

    setCategoryName = (parentName: string, name: string|null) => {
        if (name) {
            this.elCategoryName?.text(parentName + ": " + name)
        } else {
            this.elCategoryName?.text(parentName)
        }
    }

    updateSeo = (seo: any) => {
        this.elH1?.html(seo.h1)
        this.elSeoText?.html(seo.seoTextShort)
        this.elSeoFollow?.text((seo.follow ? 'index' : 'noindex'))
        if (seo.follow) {
            this.elSeoFollow?.addClass('isFollow')
        } else {
            this.elSeoFollow?.removeClass('isFollow')
        }

        $('meta[name="description"]').attr("content", seo.metaDesc)
        $('meta[name="keywords"]').attr("content", seo.metaKeywords)
        $('meta[name="robots"]').attr("content", (seo.follow ? 'follow, index' : 'follow, noindex'))
        $('title').text(seo.title)
    }

    updateSeoFooter = (seoFooter: string) => {
        this.elSeoFull?.replaceWith(seoFooter)
    }

    updateDebugThree = (three: string) => {
        const debugThree = this.elForm?.find('.debug-seo')
        if (debugThree && debugThree.length > 0) {
            debugThree.replaceWith(three)
        } else {
            if (three) {
                this.elForm?.append(three)
            }
        }
    }

    updateCatalog = (catalog: string) => {
        this.updateCatalogData(catalog)
        if (this.app.currentPage != "home") {
            // this.sliderInit();
        }
    }

    updateBreadcrumbs = (breadcrumbs: string) => {
        console.log('updateBreadcrumbs', breadcrumbs)
        if (this.elBreadcrumbs && this.elBreadcrumbs.length > 0) {
            this.elBreadcrumbs.html(breadcrumbs)
        } else {
            this.elHeaderWrapper?.prepend('<div class="breadcrumbs">'+breadcrumbs+'</div>')
        }
    }

    removeBreadcrumbs = () => {
        this.elBreadcrumbs?.remove()
    }

    updateCatalogData = (data: string) => {
        this.elCatalogs?.replaceWith(data)
        this.refreshElements()
        // this.lazyImages()
        this.app.catalog?.lazyImages(this.elImages)
    }

    windowBack = async (history: HistoryRegion) => {
        console.log('windowBack HistoryRegion', history)
        this.serviceId = history.serviceId ?? 0
        await this.getCityAndCatalog(history, true)
    }
}
