import {ModalReviewHtml} from "../modal/modal-review-html";

export class ModalReview {
    app: app.App

    constructor(app: app.App) {
        this.app = app
    }

    showEdit = (reviewData: ReviewData, callback: Function, data: ReviewSave) => {
        let modalReview = new ModalReviewHtml(this.app)
        modalReview.changeTitle("Изменить отзыв на " + reviewData.worksheetName)
        modalReview.showEdit(reviewData, callback, data)
    }
    show = (worksheetName: string, callback: Function, data: ReviewSave) => {
        let modalReview = new ModalReviewHtml(this.app)
        modalReview.changeTitle("Добавить отзыв к " + worksheetName)
        modalReview.show(callback, data)
    }

}

export interface ReviewData {
    reviewId?: number|null
    worksheetId: number
    worksheetName: string
    rating: number
    photoApprove: boolean
    name: string
    message: string
}
export interface ReviewSave {
    reviewId?: number|null
    worksheetId: number
    formData: string
}
