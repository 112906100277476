import {getKeyFromSearch, getOffset, getParameterByName} from '../functions'
import { Placeholder } from './placeholder'
import { HistoryCatalog } from '../history/historyCatalog';
import { Form } from '../catalog/form';
import { FormFilters } from '../catalog/form-filters';
import { HistoryRegion } from '@/js/history/historyRegion';
import {App} from "@/js/compile/app";

export class Catalog {
    app: app.App
    form: Form
    formFilters: FormFilters = new FormFilters()

    formElements = {
        sorting: $('select[name="sort_"]'),
        countWorksheets: $('.statistics-text span'),
    }

    $this: any
    el:JQuery<HTMLElement> | undefined
    elCatalogs:JQuery<HTMLElement> | undefined
    elCatalogLast:JQuery<HTMLElement> | undefined
    elLastCatalogItems:JQuery<HTMLElement> | undefined
    elSectionCatalog:JQuery<HTMLElement> | undefined
    elSectionCatalogText:JQuery<HTMLElement> | undefined
    elCountWithDistance:JQuery<HTMLElement> | undefined
    elPagination:JQuery<HTMLElement> | undefined
    elDistance:JQuery<HTMLElement> | undefined
    elAjaxPagination:JQuery<HTMLElement> | undefined
    elButtonRadius:JQuery<HTMLElement> | undefined
    elImages:JQuery<HTMLElement> | undefined
    elBreadcrumbs:JQuery<HTMLElement> | undefined
    elSeoLinks:JQuery<HTMLElement> | undefined
    radius = 0
    catalogQuery = {
        catalog: '.flex-container.ajaxresult',
        lastCatalog: '.flex-container.ajaxresult:last()',
        sectionCatalog: '#sectionCatalog',
        sectionCatalogText: '.seo-footer',
        pagination: '.pagination',
        countWithDistance: '.distance.find-with-distance .count',
        lastDistance: '.distance:last()',
        ajaxPagination: '.ajax-pagination',
        buttonRadius: '.button-radius',
        nearestWorksheetsLast: '.nearest-worksheets:last()',
        notFoundWorksheets: '.distance.not-found',
        catalogItems: '.catalog-item',
        images: '.img-wrapper img.loading',
        breadcrumbs: '.breadcrumbs',
        seoLinks: '.catalog-links',
    }

    placeholder: Placeholder

    constructor($this: any, app: app.App) {
        this.$this = $this
        this.app = app
        this.form = new Form(this.app, $this)
        this.placeholder = new Placeholder(this.app)
        this.form.distance = 0
        this.form.page = this.app.currentRoute.page ? parseInt(this.app.currentRoute.page) : 1
        this.form.city = this.app.currentRoute.city?.name ?? ""
        this.form.region = this.app.currentRoute.region?.name ?? ""
        this.form.service = this.app.currentRoute.service?.name ?? this.app.currentRoute.category?.name ?? ""
        this.form.cityFromCookie = this.app.currentRoute.cityFromCookie ?? false

        if (!this.app.isCopy) {
            this.refreshElements()
            this.updatePositionSeoShortMore()
        }
    }

    refreshElements = () => {
        this.el = $(this.$this).find(this.catalogQuery.catalog).first()
        this.elCatalogs = $(this.$this).find(this.catalogQuery.catalog)
        this.elCountWithDistance = $(this.$this).find(this.catalogQuery.countWithDistance).first()
        this.elPagination = $(this.$this).find(this.catalogQuery.pagination).first()
        this.elDistance = $(this.$this).find(this.catalogQuery.lastDistance).first()
        this.elAjaxPagination = $(this.$this).find(this.catalogQuery.ajaxPagination).first()
        this.elCatalogLast = $(this.$this).find(this.catalogQuery.lastCatalog).first()
        this.elSectionCatalog = $(this.$this).find(this.catalogQuery.sectionCatalog).first()
        this.elSectionCatalogText = $(this.catalogQuery.sectionCatalogText).first()
        this.elImages = $(this.$this).find(this.catalogQuery.images)
        this.elButtonRadius = this.elAjaxPagination.find(this.catalogQuery.buttonRadius).first()
        this.elLastCatalogItems = this.elCatalogs.last().find(this.catalogQuery.catalogItems)
        // if (this.elButtonRadius.length > 0) {
        //     this.radius = parseInt(this.elButtonRadius.find('span').text().replace('+', ''))
        // }
        this.elBreadcrumbs = $(this.$this).find(this.catalogQuery.breadcrumbs)
        this.elSeoLinks = $(this.$this).find(this.catalogQuery.seoLinks)

        console.log('refreshElements', $(this.catalogQuery.sectionCatalogText))
    }

    syncFiltersAfterLoad = () => {
        this.app.filters.el.basicFilterCells?.find('ul').each((i, el) => {
            const filterName = $(el).data().name;
            if (filterName === 'categories') {
                $(el).find('input:checked').each((index, elInput) => {
                    const idFilter = $(elInput).parents('li').data().id
                    this.formFilters.addFilter(filterName, idFilter)
                })
            }
        })
        this.placeholder.elFiltersDesktop?.find('span[data-filter-name]').each((index, filter) => {
            const filterName = $(filter).data().filterName

            if (filterName !== 'all-filters') {
                let id = $(filter).data().valueId
                const range = $(filter).data().range
                if (range) {
                    if (range === 'to') {
                        const rangePartFrom = this.placeholder.elFiltersDesktop?.find('span[data-filter-name="'+filterName+'"][data-range="from"]')
                        if (rangePartFrom && rangePartFrom.length > 0) {
                            id = `${rangePartFrom.data().valueId},${id}`
                        } else {
                            id = `-1,${id}`
                        }
                    } else if (range === 'from') {
                        const rangePartTo = this.placeholder.elFiltersDesktop?.find('span[data-filter-name="'+filterName+'"][data-range="to"]')
                        if (rangePartTo && rangePartTo.length > 0) {
                            id = `${id},${rangePartTo.data().valueId}`
                        } else {
                            id = `${id},-1`
                        }
                    }
                }

                this.formFilters.addFilter(filterName, id)
            }
        })
    }

    addListenerCards = () => {
        let catalog = $('main.main.page')
        if (catalog.length > 0) {

            let touchStartX = 0;
            let touchStartY = 0;
            let touchThreshold = 10;

            // @ts-ignore
            function handleTouchStart(event) {
                touchStartX = event.touches[0].clientX;
                touchStartY = event.touches[0].clientY;
            }

            // @ts-ignore
            function handleTouchEnd(event) {
                let touchEndX = event.changedTouches[0].clientX;
                let touchEndY = event.changedTouches[0].clientY;

                let deltaX = Math.abs(touchEndX - touchStartX);
                let deltaY = Math.abs(touchEndY - touchStartY);

                if (deltaX < touchThreshold && deltaY < touchThreshold) {
                    // Обработка клика
                    goToUrl(event)
                }
            }

            function goToUrl(event: MouseEvent|TouchEvent) {

                let target = event.target;
                if (target) {
                    // @ts-ignore
                    let parent = target.closest(".img-wrapper")
                    if (parent) {
                        let link = parent.querySelector("a[data-salon-id], a[data-worksheet-id]");
                        console.log('click catalog link', link)
                        if (link) {
                            event.preventDefault()
                            event.stopPropagation();
                            window.location.href = link.getAttribute("href")
                            // alert("Вы кликнули на элемент с классом img-wrapper!" + link.getAttribute("href"));
                        }
                    }
                }
            }

            catalog[0].addEventListener('touchstart', handleTouchStart);
            catalog[0].addEventListener('touchend', handleTouchEnd);
            catalog[0].addEventListener('click', goToUrl);
        }
    }

    addListenerPagination = () => {
        this.elPagination?.find('li').on('click', (e) => {
            e.preventDefault()
            const a = $(e.currentTarget).find('a')
            if (a.length > 0) {
                this.form.page = parseInt(a.data().page)

                if (!this.form.distance && this.elDistance && this.elDistance.find('span').length > 0) {
                    this.form.distance = parseInt(this.elDistance.find('span').text());
                }

                if (this.elPagination?.hasClass("search")) {
                    this.catalogAjaxSearch(true, false, true)
                } else {
                    this.catalogAjax(true, false, true)
                }
            }
        })

        this.elButtonRadius?.on('click', async (e) => {
            e.preventDefault()
            const radius: number = parseInt($(e.currentTarget).data().nextDistance)
            this.app.catalog?.form.formTopFilters?.setRadius(radius)

            this.form.isChangeRadiusByButton = true
            await this.catalogAjax(false)
            this.form.isChangeRadiusByButton = false
            this.scrollToDistance(radius.toString())
        })
    }

    updateCountWithDistance = (text: string) => {
        this.elCountWithDistance?.text(text)
    }

    setDistance = (distance: number) => {
        this.form.distance = distance
    }

    setPage = (page: number) => {
        this.form.page = page
    }

    catalogAjax = async (scrollToStart: boolean, isChangeWindowState = false, isChangeFilter = false) => {
        return new Promise<void>((resolve, reject) => {
            console.log('catalogAjax')
            const $this = this
            let link = "/api/worksheets/get-service";
            $.post( link, this.getFormData(), async function (data) {
                await $this.catalogUpdateData(data, scrollToStart, isChangeWindowState, isChangeFilter)
                resolve()
            }).fail((e) => reject(e.responseJSON))
        })
    }

    catalogAjaxSearch = async (scrollToStart: boolean, isChangeWindowState = false, isChangeFilter = false) => {
        return new Promise<void>((resolve, reject) => {
            console.log('catalogAjax')
            const $this = this
            let link = "/api/search/paginate";
            let phrase = getParameterByName("p")
            $.post( link, {...this.getFormData(), ...{p: phrase}}, async function (data) {
                await $this.catalogUpdateData(data, scrollToStart, isChangeWindowState, isChangeFilter)
                resolve()
            }).fail((e) => reject(e.responseJSON))
        })
    }

    catalogAjaxGeo = async (dataRequest: Cities.AjaxCatalog) => {
        return new Promise<void>((resolve, reject) => {
            console.log('catalogAjaxGeo')
            const $this = this
            let link = "/api/worksheets/get-service";
            this.form.city = ""
            this.form.region = ""
            this.form.page = 1
            this.form.lat = dataRequest.lat ?? null
            this.form.long = dataRequest.long ?? null
            $.post( link, {...this.getFormData(), ...dataRequest}, async function (data) {
                console.log("catalogAjaxGeo data", data)
                const cityData: Dto.CityRegionDto = data.data.cityRegionDto
                if(data.data.count === 0) {
                    $this.app.modal?.addModal({name: "notFoundWorksheets"}, 10, cityData.city.name_translate)
                    resolve()
                    return
                }
                await $this.catalogUpdateData(data, false, false, true)

                const city = <app.GeoCity> {
                    place_id: cityData.city.place_id,
                    name: cityData.city.name,
                    name_translate: cityData.city.name_translate,
                    parent_name: cityData.region.name,
                    parent_name_translate: cityData.region.name_translate,
                    cityId: cityData.region.id,
                    subCityId: cityData.city.id,
                    lat: cityData.city.lat,
                    long: cityData.city.long,
                    country: cityData.city.country,
                    parent_in_url: cityData.region.in_url
                }
                $this.app.location?.setCityFromGeo(city)
                $this.app.location?.changeCityInSelect(city.parent_name_translate ?? null, city.name_translate ?? null, city.parent_in_url ?? false, false)

                $this.form.city = cityData.city.name ?? ""
                $this.form.region = cityData.region.name ?? ""
                resolve()
            }).fail((e) => reject(e.responseJSON))
        })
    }

    catalogUpdateData = (data: any, scrollToStart: boolean, isChangeWindowState = false, isChangeFilter = false) => {
        return new Promise<void>((resolve, reject) => {
            this.form.formTopFilters.selectRadius(parseInt(data.data.distance), data.data.distanceDisabled)
            this.app.header?.changeMenuHtml(data.data.headerMenu)
            this.replacePagination(data.data.pagination)
            this.replacePlaceholder(data.data.placeholder)
            // this.replaceActiveFilters(data.data.activeFilters)
            this.updateCatalogHtml(data.data.catalog, isChangeFilter)
            this.refreshElements()

            this.updateSeo(data.data, isChangeWindowState)
            this.replaceSeoLinks(data.data.seoLinks)
            this.updateBreadcrumbs(data.data.breadcrumbs)
            this.addListenerPagination()

            if (scrollToStart) {
                this.scrollToStartCatalog()
            }
            this.lazyImages(this.elImages)

            resolve()
        })
    }

    getFormData = () => {
        this.form.distance = this.app.catalog?.form.formTopFilters?.currentRadius ?? -1
        return {
            sorting: this.form.sorting,
            page: this.form.page,
            city: this.form.city,
            region: this.form.region,
            service: this.form.service,
            distance: this.form.distance,
            cityFromCookie: this.form.cityFromCookie,
            isChangeRadiusByButton: this.form.isChangeRadiusByButton,
            filters: this.formFilters.filters
        }
    }

    updateCatalogHtml = (htmlCatalog: string, isChangeFilter = false) => {
        console.group('updateCatalogHtml')

        const lastDistance = this.elCatalogLast?.find('.distance[data-distance]').last().data()?.distance
        console.log('updateCatalogHtml lastDistance', lastDistance)

        const newElem = document.createElement("div");
        newElem.innerHTML = htmlCatalog

        const copyApp = {...this.app}
        copyApp.isCopy = true
        const updateCatalog = new Catalog(newElem, copyApp)
        updateCatalog.refreshElements()

        if (isChangeFilter) {
            this.replaceFullCatalog(htmlCatalog)
        } else {
            this.elCountWithDistance?.text(updateCatalog.elCountWithDistance?.text() ?? "")

            const removeResult = updateCatalog.removeInCatalogToItemId(lastDistance)
            console.log('updateCatalogHtml removeResult', removeResult)
            if (removeResult) {
                if ($(updateCatalog.$this).children().text().trim().length > 0) {
                    this.elCatalogLast?.after($(updateCatalog.$this).children())
                }
            } else {
                this.replaceFullCatalog(htmlCatalog)
            }
        }
        this.refreshElements()

        console.groupEnd()
    }

    replaceFullCatalog = (htmlCatalog: string) => {
        this.elCatalogs?.each((index, elem) => {
            if (index === 0) {
                $(elem).replaceWith(htmlCatalog)
            } else {
                $(elem).remove()
            }
        })
    }

    getCountItems = () => {
        return this.elLastCatalogItems?.length ?? 0
    }

    removeInCatalogToItemId = (distance: string) => {
        console.log('removeInCatalogToItemId')
        const findItem = $(this.$this).find('.distance[data-distance="'+distance+'"]')
        if (findItem.length > 0) {
            while (true) {
                if (findItem.next().length === 0 || findItem.next().hasClass('distance')) {
                    break;
                }
                findItem.next().remove()
            }
            while (true) {
                if (findItem.prev().length > 0) {
                    findItem.prev().remove()
                } else {
                    break
                }
            }
            findItem.remove()
            this.refreshElements()
            return true
        }
        return false
    }

    replacePagination = (htmlPagination: string) => {
        this.elAjaxPagination?.replaceWith(htmlPagination)
    }

    replacePlaceholder = (htmlPlaceholder: string) => {
        this.placeholder.changeHtmlPlaceholder(htmlPlaceholder)
    }
    replaceActiveFilters = (htmlActiveFilters: string) => {
        this.placeholder.changeHtmlActiveFilters(htmlActiveFilters)
    }
    replaceSeoLinks = (htmlSeoLinks: string) => {
        this.elSeoLinks?.html(htmlSeoLinks)
    }

    scrollToDistance = (countDistance: string) => {
        const navigateTo = $(this.$this).find('.distance-' + countDistance)?.last();
        if (navigateTo?.length > 0) {
            // @ts-ignore
            window.scroll({ top: getOffset(navigateTo[0]).top - 15, behavior: "instant"})
        }
    }
    scrollToPagination = () => {
        if (this.elPagination && this.elPagination?.length > 0) {
            const topScroll = getOffset(this.elPagination[0]).top
            // @ts-ignore
            window.scroll({ top: topScroll, behavior: "instant"})
        }
    }
    scrollToStartCatalog () {
        const $this = this
        const top = getOffset($this.elSectionCatalog?.first()[0]).top
        // @ts-ignore
        window.scroll({ top: top - 15, behavior: "instant"})
    }

    lazyImages = (images: JQuery<HTMLElement>|undefined) => {
        if (!images) {
            return
        }
        function loadedImage(img: HTMLElement) {
            img.classList.remove("loading")
            img.classList.add('loaded')
            $(img).prev().addClass('hide')
        }
        function imageIsLoaded(img: HTMLElement) {
            // @ts-ignore
            if (!img.complete) {
                return false;
            }
            // @ts-ignore
            return img.naturalWidth !== 0;
        }

        images?.each((index, img) => {
            if (imageIsLoaded(img)) {
                loadedImage(img)
            } else {
                img.addEventListener('load', function(e) {
                    loadedImage(img)
                })
            }
        })
    }

    updateSeo = (data: any, isChangeWindowState = false) => {
        console.group('updateSeo', data.seo)
        if (data.seo) {
            const elemFollow = $(this.$this).find('.page__title.follow')
            $('meta[name="description"]').attr("content", data.seo.metaDesc)
            $('meta[name="keywords"]').attr("content", data.seo.metaKeywords)
            $('meta[name="robots"]').attr("content", (data.seo.follow ? 'follow, index' : 'follow, noindex'))
            $('title').text(data.seo.title)
            $(this.$this).find('h1.page__title')?.text(data.seo.h1)
            elemFollow?.text((data.seo.follow ? 'index' : 'noindex'))

            this.updateSeoTextShort(data.seo.seoTextShort)
            this.updatePositionSeoShortMore()

            if (data.seo.follow) {
                elemFollow.addClass('isFollow')
            } else {
                elemFollow.removeClass('isFollow')
            }
            if (data.debugThree) {
                $(this.$this).find('.debug-seo').replaceWith(data.debugThree)
            }
        }

        this.updateSeoText(data.seoText)


        data.page = data.page.replace('page=1', '').replace(/\?$/, '').replace(/\?+/, '?')

        if (!isChangeWindowState) {
            const historyData = new HistoryCatalog().create(this).getState()
            console.log('catalog pushstate', historyData)
            console.log('catalog pushstate data.page', data.page)
            let trimPage = data.page.replace(/^\//, '');
            window.history.pushState(historyData, '', "/" + trimPage)
        }
        console.groupEnd()
    }

    updateBreadcrumbs = (breadcrumbs: string) => {
        this.elBreadcrumbs?.html(breadcrumbs)
    }

    updateSeoText = (seoText: string) => {
        console.log('updateSeo', this.elSectionCatalogText, seoText.length)

        if (this.form.page && this.form.page > 1) {
            console.log('updateSeo remove', this.elSectionCatalogText)
            this.elSectionCatalogText?.remove()
        } else if (seoText.length === 0 ) {
            this.elSectionCatalogText?.remove()
        } else if (this.form.page && this.form.page === 1) {
            if (this.elSectionCatalogText?.length === 0) {
                this.elCatalogs?.parents('main').append(seoText)
                // this.elPagination?.parent().append(seoText)
            }
        }
        this.elSectionCatalogText?.replaceWith(seoText)

    }

    updateSeoTextShort = (seoShort: string) => {
        $('.catalog-text-short').html(seoShort)
    }

    updatePositionSeoShortMore = () => {
        let blockText = $('.catalog-text-short')
        let moreBlock = blockText.find('.more-block')
        let more = blockText.find('a.more')
        if (blockText && blockText.length > 0 && more && moreBlock && more.length > 0) {
            more?.css({visibility: 'hidden', right: "-9px"})
            let offsetBlockText = blockText[0].getBoundingClientRect()
            let offsetMoreBlock = moreBlock[0].getBoundingClientRect()
            let offsetMore = more[0].getBoundingClientRect()
            // console.log('more', offsetMoreBlock, offsetBlockText)

            if (offsetMoreBlock.left - offsetBlockText.left < offsetMore.width ) {
                let addRight = offsetMore.width
                more.css({right: "-" + addRight + "px", visibility: "visible"})
            } else {
                more.css({right: "-" + 9 + "px", visibility: "visible"})
            }
        }
    }

    addListenerSorting = () => {
        this.formElements.sorting.on('change', (e) => {
            this.form.sorting = parseInt($(e.target)?.val()?.toString() ?? "1")
            this.form.page = 1
            this.catalogAjax(true)
        })
    }

    getParams = () => {
        this.form.sorting = parseInt(this.formElements.sorting.val()?.toString() ?? "1")
        if (this.app.currentRoute.page) {
            this.form.page = parseInt(this.app.currentRoute.page)
        }
    }

    windowBack = async (history: HistoryCatalog) => {
        console.log('windowBack HistoryCatalog', history)
        this.form.sorting = history.sorting
        this.form.page = history.page
        this.form.city = history.city
        this.form.region = history.region
        this.form.service = history.service
        this.form.distance = history.distance
        this.form.formTopFilters?.setCurrentRadius(history.distance)
        this.formFilters.filters = history.filters
        this.app.filters?.syncByFilters()
        await this.catalogAjax(false, true)
    }


}
