import { UncheckedFilters } from './unchecked-filters';

export class Filters {
    app: app.App
    el: app.FiltersEls = {
        body: undefined,
        containerBasic: undefined,
        basicFiltersDropDown: undefined,
        basicFilterBlockDropDown: undefined,
        basicFilterDropDownBack: undefined,
        basicFilterCells: undefined,
        basicFilterButtons: undefined,
        basicFilterListsLi: undefined,
        basicFilterListsInputs: undefined,
        basicFilterListsSelect: undefined,
    }
    elModal: app.FilterModalEls = {
        containerModal: undefined,
        buttonsOpenModal: undefined,
        buttonCloseModal: undefined,
    }
    elSubstrate: app.FilterSubstrateEls = {
        substrate: undefined,
        filters: undefined,
    }

    elGroup: JQuery<HTMLElement> | undefined
    elGroupHeader: JQuery<HTMLElement> | undefined
    elGroupBody: JQuery<HTMLElement> | undefined

    queries: app.FiltersQueries = {
        body: 'body',
        containerBasic: '.filters-container',
        filtersGroup: '.filters-group',
        filtersHeader: '.filters-header',
        filtersBody: '.filters-body',
        basicFilterCells: '.filter-cell, .geolocation.sub-category',
        basicFilterButtons: '.filter-button',
        basicFilterCounter: 'span.count',
        basicFilterActive: 'span.filters-active',
        basicFiltersDropDown: '.filters-dropdown',
        basicFilterDropDown: '.catalog-filter__dropdown',
        basicFilterDropDownBack: '.catalog-filter__back',
        basicFilterListsLi: 'li.list-checkbox__item',
        basicFilterListsInputs: 'li.list-checkbox__item input',
        basicFilterListsSelect: 'select',
    }
    queriesModal = {
        containerModal: '.m-filter',
        buttonsOpenModal: 'a.filters-mobile',
        buttonCloseModal: '.m-filter-close',
    }
    queriesSubstrate = {
        substrate: '.ajaxresult .placeholder',
        filters: '.filters',
    }

    uncheckedFilters: UncheckedFilters

    constructor(app: app.App) {
        this.app = app
        this.uncheckedFilters = new UncheckedFilters(this.app, this)
        this.refreshElements()
        this.addListeners()
        this.initChecked()
    }

    refreshElements = () => {
        this.el.body = $(this.queries.body)
        this.el.containerBasic = $(this.queries.containerBasic)
        this.el.basicFilterBlockDropDown = this.el.containerBasic?.find(this.queries.basicFilterDropDown)
        this.el.basicFilterCells = this.el.containerBasic.find(this.queries.basicFilterCells)
        this.el.basicFilterButtons = this.el.containerBasic.find(this.queries.basicFilterButtons)
        this.el.basicFilterDropDownBack = this.el.containerBasic.find(this.queries.basicFilterDropDownBack)
        this.el.basicFiltersDropDown = this.el.containerBasic.find(this.queries.basicFiltersDropDown)
        this.el.basicFilterListsLi = this.el.containerBasic.find(this.queries.basicFilterListsLi)
        this.el.basicFilterListsInputs = this.el.containerBasic.find(this.queries.basicFilterListsInputs)
        this.el.basicFilterListsSelect = this.el.containerBasic.find(this.queries.basicFilterListsSelect)

        this.elModal.buttonsOpenModal = $(this.queriesModal.buttonsOpenModal)
        this.elModal.containerModal = $(this.queriesModal.containerModal)
        this.elModal.buttonCloseModal = this.elModal.containerModal.find(this.queriesModal.buttonCloseModal)

        this.elSubstrate.substrate = $(this.queriesSubstrate.substrate)
        this.elSubstrate.filters = this.elSubstrate.substrate.find(this.queriesSubstrate.filters)

        this.elGroup = this.el.containerBasic?.find(this.queries.filtersGroup)
        this.elGroupHeader = this.elGroup?.find(this.queries.filtersHeader)
        this.elGroupBody = this.elGroup?.find(this.queries.filtersBody)
    }

    addListeners = () => {
        console.log('filters addListeners')
        this.elModal.buttonsOpenModal?.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.elModal.containerModal?.toggleClass('opened')
            this.el.body?.toggleClass('overflow-hidden')
        })
        this.elModal.buttonCloseModal?.on('click', (e) => {
            this.elModal.containerModal?.toggleClass('opened')
            this.el.body?.toggleClass('overflow-hidden')
            e.stopPropagation();
        })
        this.el.basicFilterDropDownBack?.on('click', (e) => {
            $(e.currentTarget).parent().parent().find('.is-active').toggleClass('is-active')
            e.stopPropagation();
        })
        this.el.basicFilterListsLi?.on('click', (e) => {
            e.stopPropagation();
        })
        this.el.basicFilterListsInputs?.on('change', (e) => {
            this.setUnsetByInput($(e.currentTarget), true)
            e.stopPropagation();
        })
        this.el.basicFilterListsSelect?.on('change', (e) => {
            this.setUnsetBySelect($(e.currentTarget), true)
            e.stopPropagation();
        })
        this.el.basicFilterCells?.on('click', (e) => {
            if ($(e.currentTarget).hasClass('is-active')) {
                this.closeOpenedDropDowns(true)
            } else {
                this.closeOpenedDropDowns(true)
                $(e.currentTarget).find(this.queries.basicFilterDropDown).toggleClass('is-active')
                $(e.currentTarget).toggleClass('is-active')
            }
            e.stopPropagation();
        })
        this.el.basicFilterBlockDropDown?.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation();
        })
    }

    closeOpenedDropDowns = (closeModals: boolean) => {
        this.el.basicFiltersDropDown?.find('.is-active').each((e, elem) => {
            $(elem).toggleClass('is-active')
        })
        if (closeModals) {
            this.app.location?.closeModalsAll()
        }
    }

    setCheckedFilter = (filterName: string, id: string|number, isActive: boolean) => {
        const li = this.el.basicFilterListsLi?.parents(`ul[data-name="${filterName}"]`)
                                              .find(`li[data-id="${id}"]`)
        if (li) {
            const jq = li?.find('.jq-checkbox-own')
            if (isActive) {
                jq.addClass('checked')
            } else {
                jq.removeClass('checked')
            }
            jq.find('input').prop( "checked", isActive )
            let textChecked = this.uncheckedFilters.liCheckedText(li)

            const isSingle = li.filter('[data-single]').length > 0
            if (isSingle) {
                const dataLi = li.data()
                if (isActive) {
                    li.closest('.catalog-filter__scroll').find('select, input:checkbox:not(:checked)').each((index, el) => {
                        const name = $(el).data().name ?? null
                        if (name) {
                            this.app.catalog?.formFilters.removeFilters(name, false)
                        }
                        if (name === 'ages_from_to') {
                            if ($(el).data().rangeTo !== undefined) {
                                $(el).val(dataLi.to)
                            }
                            if ($(el).data().rangeFrom !== undefined) {
                                $(el).val(dataLi.from)
                            }
                        }
                        $(el).prop('disabled', 'disabled')
                                                .parents('.list-checkbox__item')
                                                .addClass('disabled_checkbox')
                    })
                } else {
                    li.closest('.catalog-filter__scroll').find('select, input').each((index, el) => {
                        const name = $(el).data().name ?? null
                        if (name === 'ages_from_to') {
                            if ($(el).data().rangeTo !== undefined) {
                                $(el).val(-1)
                            }
                            if ($(el).data().rangeFrom !== undefined) {
                                $(el).val(-1)
                            }
                        }
                        $(el).prop('disabled', '')
                                                .parents('.list-checkbox__item')
                                                .removeClass('disabled_checkbox')
                    })
                }
            }
            this.uncheckedFilters.setCountCheckedList(li, textChecked[0], textChecked[1])
        }
    }

    setCheckedSelect = (filterName: string, id: number|any[]) => {
        console.log('setCheckedSelect', filterName, id)
        let find = this.el.basicFilterListsSelect?.filter('[data-name="' + filterName + '"]')
        if (Array.isArray(id)) {
            let from = find?.filter('[data-range-from]').val(id[0])
            if (id[0] === -1) {
                from?.removeClass('checked')
            } else {
                from?.addClass('checked')
            }

            let to = find?.filter('[data-range-to]').val(id[1]).addClass('checked')
            if (id[1] === -1) {
                to?.removeClass('checked')
            } else {
                to?.addClass('checked')
            }
        } else {
            if (id === -1) {
                find?.removeClass('checked')
            } else {
                find?.addClass('checked')
            }
        }
    }

    setUnsetByInput = (el: JQuery, updateCatalog: boolean) => {
        const isChecked = el.prop('checked')
        const textPlaceholder = el.parents('li[data-name]')[0]?.dataset.placeholderName ?? ""
        const textFilter = el.parents('.filter-cell').find('.catalog-filter__back > span').text() ?? ""
        const id = el.parents('li[data-name]')[0]?.dataset.id ?? ""
        const filterName = el.parents('ul[data-name]')[0]?.dataset.name ?? ""

        this.setCheckedFilter(filterName, id, isChecked)
        console.log('setUnsetByInput', filterName, id, isChecked)
        if (isChecked) {
            this.app.catalog?.placeholder.addFilter(filterName, textFilter, textPlaceholder, parseInt(id), updateCatalog, {name: "null"})
        } else {
            this.app.catalog?.placeholder.removeFilter(filterName, parseInt(id), {name: "null"});
        }
    }

    setUnsetBySelect = (el: JQuery, updateCatalog: boolean) => {
        let id: number|any[] = -1
        const val = parseInt(<string>el.val())
        const filterName = el.data().name ?? ""

        const isRange: Enums.IsRange = {name: "null"}
        if (el.data().rangeFrom !== undefined) {
            isRange.name = "from"
        } else if (el.data().rangeTo !== undefined) {
            isRange.name = "to"
        }

        let text = ""
        let length = 1

        if (isRange.name !== 'null') {
            if (isRange.name === 'to') {
                let idFrom = parseInt(<string>el.closest('.catalog-filter__dropdown').find('[data-name="' + filterName + '"][data-range-from]').val())
                console.log('idFrom', idFrom)
                if (val && idFrom !== -1 && val !== -1 && val < idFrom) {
                    idFrom = val
                }
                id = [idFrom ?? -1, val ?? -1]
            } else {
                let idTo = parseInt(<string>el.closest('.catalog-filter__dropdown').find('[data-name="' + filterName + '"][data-range-to]').val())
                console.log('idTo', idTo)
                if (val && idTo !== -1 && val !== -1 && val > idTo) idTo = val
                id = [val ?? -1, idTo ?? -1]
            }
            if (id[0] === -1 && id[1] === -1) {
                length = 0;
            } else if (id[0] !== -1 && id[1] === -1) {
                text = "От " + id[0]
            } else if (id[0] === -1 && id[1] !== -1) {
                text = "До " + id[1]
            } else if (id[0] === id[1]) {
                text = id[0]
            } else {
                text = id[0] + "-" + id[1]
            }
        } else {
            id = val
            if (id === -1) {
                length = 0
            }
            if (filterName === 'distance') {
                text = (id > 0 ? "+" : "") + id.toString() + "km"
                if (id === -1) {
                    this.app.catalog?.setDistance(-1)
                }
            } else {
                text = id.toString()
            }
        }

        let placeholderName = el.data().placeholderName ?? ""

        if (Array.isArray(id) && id[0] !== -1 && id[1] !== -1) {
            let translateFix = el.parents('div[data-placeholder-fix-name]')
            placeholderName = translateFix.data().placeholderFixName

            if (id[0] === id[1]) {
                isRange.name = 'fix';
                placeholderName = placeholderName.replace(':count', val)
            } else {
                isRange.name = 'range';
                placeholderName = placeholderName.replace(':count', `${id[0]}-${id[1]}`)
            }
        } else {
            placeholderName = placeholderName.replace(':count', val)
        }


        this.setCheckedSelect(filterName, id)
        this.uncheckedFilters.setCountCheckedList(el, length, text)
        if (val === -1) {
            this.app.catalog?.placeholder.removeFilter(filterName, id, isRange)
        } else {
            this.app.catalog?.placeholder.addFilter(filterName, text, placeholderName, id, updateCatalog, isRange)
        }
    }

    unsetSelectByRange = (filterName:string, range: Enums.IsRange) => {
        if (range.name !== 'null') {
            if (range.name !== 'fix' && range.name !== 'range') {
                const elFromTo = $('select[data-name="'+filterName+'"][data-range-' + range.name + ']');
                    elFromTo.find('[data-id="-1"]').prop('selected', true);
                this.setUnsetBySelect(elFromTo, false);
            } else {
                const elFrom = $('select[data-name="'+filterName+'"][data-range-from]')
                    elFrom.find('[data-id="-1"]').prop('selected', true);
                this.setUnsetBySelect(elFrom, false);

                const elTo = $('select[data-name="'+filterName+'"][data-range-to]');
                    elTo.find('[data-id="-1"]').prop('selected', true);
                this.setUnsetBySelect(elTo, false);
            }
        }
    }


    initChecked = () => {
        // this.el.basicFilterListsSelect?.each((index, el) => {
        //     if (parseInt(<string>$(el).val()) !== -1) {
        //         if ($(el).data().name === 'distance') {
        //             if (parseInt(<string>$(el).val()) !== 0) {
        //                 this.setUnsetBySelect($(el), false)
        //             }
        //         } else {
        //             this.setUnsetBySelect($(el), false)
        //         }
        //     }
        // })
        // this.el.basicFilterListsInputs?.each((index, el) => {
        //     if ($(el).prop('checked')) {
        //         // this.setUnsetByInput($(el), false)
        //     }
        // })
    }

    syncByFilters = () => {
        this.el.basicFilterCells?.find('ul').each((i, el) => {
            const filterName = $(el).data().name;
            this.uncheckedFilters.uncheckedAllFiltersWithCategories()
            if (this.app.catalog?.formFilters.filters.hasOwnProperty(filterName)) {
                this.app.catalog?.formFilters.filters[filterName].forEach((id) => {
                    this.setCheckedFilter(filterName, id, true)
                })
            }
        })

        const countFilters = this.app.catalog?.formFilters.getCountActiveFilters()
        this.app.catalog?.form.formTopFilters.setCountFilters(countFilters ?? 0)
    }

    toggleFiltersGroup = () => {
        if (this.filtersGroupIsVisible()) {
            this.closeOpenedDropDowns(true)
        }

        // const countFiltersActive = this.app.catalog?.formFilters.getCountActiveFilters() ?? 0;
        // if (countFiltersActive === 0) {
        //     this.elGroupHeader?.toggleClass('opened');
        //     this.elGroupBody?.toggleClass('opened');
        // } else if (countFiltersActive > 0) {
        //     this.elGroupBody?.toggleClass('opened');
        // }

        const countFiltersActive = this.app.catalog?.formFilters.getCountActiveFilters() ?? 0;
        if (countFiltersActive === 0) {
            this.elGroupHeader?.slideToggle(100).toggleClass('opened');
            this.elGroupBody?.slideToggle(100).toggleClass('opened');
        } else if (countFiltersActive > 0) {
            this.elGroupBody?.slideToggle(100).toggleClass('opened');
        }
    }

    closeFiltersGroup = () => {
        if (this.elGroupBody?.hasClass('opened')) {
            const countFiltersActive = this.app.catalog?.formFilters.getCountActiveFilters() ?? 0;
            if (countFiltersActive === 0) {
                this.elGroupHeader?.slideToggle(100).removeClass('opened');
            }
            this.elGroupBody?.slideToggle(100).toggleClass('opened');
            this.app.catalog?.form.formTopFilters.toggleOpenedButtonFilter()
        }
    }

    filtersGroupIsVisible = () => {
        return this.elGroup?.is(":visible") && ((this.elGroup.height() ?? 0) > 0)
    }


}

