'use strict';

var $ = jQuery;

$(window).on('load', function () {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        $('body').addClass('ios');
    } else {
        $('body').addClass('web');
    }
    $('body').removeClass('loaded');
});

function waitForWebfonts(fonts, callback) {
    var loadedFonts = 0;
    for(var i = 0, l = fonts.length; i < l; ++i) {
        (function(font) {
            var node = document.createElement('span');
            // Characters that vary significantly among different fonts
            node.innerHTML = 'giItT1WQy@!-/#';
            // Visible - so we can measure it - but not on the screen
            node.style.position      = 'absolute';
            node.style.left          = '-10000px';
            node.style.top           = '-10000px';
            // Large font size makes even subtle changes obvious
            node.style.fontSize      = '300px';
            // Reset any font properties
            node.style.fontFamily    = 'sans-serif';
            node.style.fontVariant   = 'normal';
            node.style.fontStyle     = 'normal';
            node.style.fontWeight    = 'normal';
            node.style.letterSpacing = '0';
            document.body.appendChild(node);

            // Remember width with no applied web font
            var width = node.offsetWidth;

            node.style.fontFamily = font + ', sans-serif';

            var interval;
            function checkFont() {
                // Compare current width with original width
                if(node && node.offsetWidth != width) {
                    ++loadedFonts;
                    node.parentNode.removeChild(node);
                    node = null;
                }

                // If all fonts have been loaded
                if(loadedFonts >= fonts.length) {
                    if(interval) {
                        clearInterval(interval);
                    }
                    if(loadedFonts == fonts.length) {
                        setTimeout(callback(), 100);
                        // callback();
                        return true;
                    }
                }
            };

            if(!checkFont()) {
                interval = setInterval(checkFont, 50);
            }
        })(fonts[i]);
    }
};

var loading_filter_time;

var filter_active = 0, filter_trigger = 0;

$(function () {

    var review_success = '';

    if (review_success) {
        DLEalert(review_success, dle_info);
    }

    $("#review-popup form").on("submit", function () {
        var error = '';
        if ($(this).find("[name=review_text]").val().length < 5) error = 'Напишите отзыв';
        else if ($(this).find("[name=review_name]").val().length < 2) error = 'Введите ваше имя';
        else if (!$(this).find("[name=review-rating]:checked").length) error = 'Поставьте оценку';

        if (error) {
            DLEalert(error, dle_info);
            return false;
        }
    });


    $("#review-popup textarea").on("keyup", function () {

        var autoheight = $("#review-popup textarea");
        if (autoheight.prop("scrollHeight") > 137 && autoheight.prop("scrollHeight") < 350)
            autoheight.css("height", autoheight.prop("scrollHeight"));

    })


    // Review answer
    $('.review-answer-button, .review-answer-edit').on('click', function (e) {
        e.preventDefault();
        $(this).next('.review-answer-form').slideToggle();
    });


    // PRODUCT

    $('.product-thumbs-counter span').html($('.product-thumb').length);

    $('.product-about-less .product-about-button').on('click', function () {
        $('.product-about-less').hide();
        $('.product-about-more').show();
    });

    $('.product-about-more .product-about-button').on('click', function () {
        $('.product-about-more').hide();
        $('.product-about-less').show();
    });


    $(".product-services h3").each(function () {
        if (!$("li", $(this).next()).length) {
            $(this).next().remove();
            $(this).remove();
        }
    });


    $('.product-services .product-services-col').each(function () {
        if ($(this).children().length == 0) {
            $(this).remove();
        }
    });

    function advClientSwitch() {
        $('.adv-client-item').each(function() {
            const jqRadio = $(this).find('.jq-radio.checked').length;
            if (jqRadio) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
        });
    }
    advClientSwitch();

    $('.adv-client-item').on('click', function() {
        advClientSwitch();
    });

    $('.adv-client-form .main-button').on('click', function() {

        if ($('[name=user-type]:checked').val() == 1) {
            $('.reg-type-title span').html('Рекламодатель');
        } else if ($('[name=user-type]:checked').val() == 2) {
            $('.reg-type-title span').html('Клиент');
        }
    });

    $('.registration-back-btn').on('click', function() {
        $(this).parents('.register_form').hide();
        $('.adv-client-form').show('slide', {direction: 'left'}, 500);
    });

})

var process_load,numpage = 1;

function selected_count() {

    setTimeout( function(){$('.filter .filter__head-item').each(function(){
        var count = $('.checked', this).length;
        var count2 = $('.js-counties .checked', this).length;
        if( count2 ) count = count - count2;

        if( count > 0 ) {
            $(this).find(".filter__trigger span").html("("+count+")")
        }else {
            $(this).find(".filter__trigger span").html("")
        }
    })}, 100);


    var countall = $('.filter input:checked').length;
    if( countall )
        $('.filter__submit > button[type=submit] span.text').text( "Применить ("+countall+")");
    else
        $('.filter__submit  > button[type=submit] span.text').text( "Применить");

}

var filter_active = 0;

$(document).ready(function () {
    if ($.hasOwnProperty("tooltipster")) {
        let tooltipTriger = 'hover';
        if ($(window).width() < 1025) {
            tooltipTriger = 'click';
        }
        $('.tooltip').tooltipster({
            delay: 100,
            trigger: tooltipTriger
        });
    }

    // $(".header-nav__list a, .nav-mobile__list a").each(function(){
    // 	if( location.href.indexOf( this.pathname ) !== -1 ) $(this).parent().addClass("active")
    // })
    /* placeholder*/
    $('input, textarea').each(function () {
        var placeholder = $(this).attr('placeholder');
        $(this).focus(function () {
            $(this).attr('placeholder', '');
        });
        $(this).focusout(function () {
            $(this).attr('placeholder', placeholder);
        });
    });
    /* placeholder*/

    /* components */

    initMagnific();

    if( location.href.indexOf("/page/") !== -1 ) {

        numpage = location.href.split("/page/")

        numpage = parseInt( numpage[1].substring(0,numpage[1].length-1 ) )
    }


    // }
    if( $(".load-more-btn").data("pagination") == "auto" && !$(".load-more-btn").data("ndis") ) {
        $(".load-more-btn").after('<div class="main_ankety" data-page="2"/>')
        $(".load-more-btn").remove()

        $(document).on( 'scroll.main_ankety', function(){
            if( !$(".main_ankety").length || process_load == 1 ) {console.log( $(".main_ankety").length , process_load );return;}
            console.log('proccess')
            if( $(".main_ankety").offset().top <= $(document).scrollTop()+$(window).height()+1000 ) {
                process_load = 1;
                load_posts()
                console.log('start')
            }

        })
    }
    initRangeSlider();

    if ($('.js-styled').length) {
        // $('.js-styled').styler();
    }


    // $('[data-fancybox="product-gallery"]').fancybox({
    //     'afterClose': function(e) {
    //         console.log('gallery closed')
    //         // e.preventDefault()
    //     }
    // });
    if( $(".catalog-card__gallery").length && location.href.indexOf("editnews") === -1 ) {

        $(".catalog-card__gallery .swiper-slide").each(function(){
            $(this).html('<a href="'+$("img", this).data('original').replace(/thumbs\//, '')+'" data-fancybox2="gallery">'+$(this).html()+'</a>') // <a href="#" class="highslide delete_highslide"></a>
        });

        $().fancybox({
            selector:'.swiper-container .swiper-slide:not(.swiper-slide-duplicate) [data-fancybox2="gallery"]',
            loop: true,
            onClosed: function() {
                console.log('gallery closed')
            }
        });

        $(document).on('click', '.swiper-container .swiper-slide-duplicate', function(e) {

            $('.swiper-container .swiper-slide:not(.swiper-slide-duplicate)')
                .eq( ( $(e.currentTarget).attr("data-swiper-slide-index") || 0) % $('.swiper-container .swiper-slide:not(.swiper-slide-duplicate) [data-fancybox2="gallery"]').length ).find('[data-fancybox2="gallery"]')
                .trigger("click.fb-start", {
                    $trigger: $(this)
                });

            return false;
        });

        var productSwiper = new Swiper('.catalog-card__gallery .swiper-container', {
            slidesPerView: 4,
            speed: 500,
            loop: true,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                },
            }
        });

        $.fancybox.defaults.backFocus = false;

    }


    if ($('.reviews .swiper-container').length) {
        var reviewsSwiper = new Swiper('.reviews .swiper-container', {
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            speed: 500
        });
    }





    if ($('.main-info__to-characteristics').length) {
        $('.main-info__to-characteristics').on('click', function (e) {
            e.preventDefault();
            $("html, body").animate({ scrollTop: $("#catalog-card__characteristics").offset().top - 40 });
        });
    }

    if ($('.js-dropdown__trigger').length) {
        $('.js-dropdown__trigger').click(function () {
            dropDown($(this));
        });
    }


    if ($('.js-nav-mobile-trigger').length && $('.js-nav-mobile').length) {
        var navToggle = $('.js-nav-mobile-trigger'),
            navMobile = $('.js-nav-mobile');
        let overlay = $('.overlay');

        navToggle.click(function () {
            $(this).toggleClass('active');
            navMobile.toggleClass('opened');
            overlay.toggleClass('opened');
            if (overlay.hasClass('opened')) {
                overlay.click(() => {
                    navToggle.removeClass('active')
                    navMobile.removeClass('opened');
                    overlay.removeClass('opened');
                })
            }
        });
    }
    //
    // if ($('.popup-filter-close').length) {
    // 	$('.popup-filter-close').click(function () {
    // 		$('body').removeClass('overflow-hidden');
    // 		$('.js-filter').removeClass('opened');
    // 	});
    // }
    //
    // if ($('.filter-submit').length) {
    // 	$('.filter-submit').click(function() {
    // 		$('body').removeClass('overflow-hidden');
    // 	});
    // }

    if ($('.js-show-txt').length) {
        $('.js-show-txt').click(function () {
            if ($(this).hasClass('active')) {
                $(this).siblings('.js-hidden-text').removeClass('active');
                $(this).removeClass('active').text('Показать весь текст');
            } else {
                $(this).siblings('.js-hidden-text').addClass('active');
                $(this).addClass('active').text('Скрыть текст');
            }
        });
    }


    if ($('.js-authorization').length) {
        $('.js-authorization').click(function (e) {
            e.preventDefault();
            $(this).next('.js-header-functionals__auth').slideToggle().toggleClass('opened');
        });
    }
    if ($('.js-profile-modal').length) {
        $('.js-profile-modal').click(function (e) {
            e.preventDefault();
            $('.js-header-functionals__profile').slideToggle().toggleClass('opened');
        });
    }

    if ($('.jstriptiz-profile-button').length) {
        $('.jstriptiz-profile-button').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('.account-list').toggleClass('opened');
        });

        $('.account-list__item .text').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active').next('.account-sublist').slideToggle();
        });
    }


    if ($('.jstriptiz-profile-button').length && $('.jstriptiz-profile-button__dropdown').length) {
        $('.jstriptiz-profile-button').click(function (e) {
            e.preventDefault();
            $(this).next('.jstriptiz-profile-button__dropdown').toggleClass('opened').slideToggle();
        });
    }

    if($('.js-scroll-top').length) {
        $('.js-scroll-top').click(function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });
    }

    // Increase additional services price
    if($('.js-add-val').length) {
        $('.js-add-val').click(function() {
            var val = parseInt($(this).siblings('input').val());

            val+=100;

            $(this).siblings('input').val(val);
        });
    }

    if($('.js-warning-message').length) {
        var msgWindow = $('.js-warning-message'),
            msgForm = msgWindow.find('.warning-message__form');

        msgForm.on('submit', function(e) {
            e.preventDefault();
            msgWindow.removeClass('opened');
        });
    }


    $('.box-field__input--phone .form-control').keyup(function(){
        if($(this).val().length==$(this).attr("maxlength")){
            $(this).next().focus();
        }
    });

    // remove optional price if empty
    $('.product-services .optional').each(function() {
        if (!this.textContent) {
            this.remove();
        }
    });

    // review popup
    if( $.fn.magnificPopup )
        $('.write-review, .open-entry-popup, .private-message-btn, .reviews-add-button, .client-functions-message, .open-popup').magnificPopup({
            type: 'inline'
        });

    // rating stars

    $('.review-popup-rating-element').each(function() {
        const ratingStart = $(this).find('.review-rating-star');

        ratingStart.on('click', function() {
            ratingStart.removeClass('active');
            $(this).addClass('active');
            $(this).nextAll().addClass('active');
        });
    });


});

function initMagnific() {
}

function initSLick(slickItem, slickVars) {
    slickItem.slick(slickVars).resize();
}

function mobileNavToggle(dropDownBtn, navItem) {
    dropDownBtn.toggleClass('active');
    removeToggle(navItem);
}

function dropDown(dropDownBtn) {
    dropDownBtn.toggleClass('active');
    var toggleBlock = dropDownBtn.parent().find('.js-dropdown__content');
    removeToggle(toggleBlock);
}

function removeToggle(toggleBlock) {
    toggleBlock.toggle(300, function () {
        if ($(this).css('display') === 'none') {
            $(this).removeAttr('style');
        }
    });
}

function initRangeSlider( reset ) {
    if ($('.js-range-slider').length) {
        var rangeSlider = $('.js-range-slider');
        for (var i = 0; i < rangeSlider.length; i++) {
            var min = rangeSlider.eq(i).attr('data-min'),
                max = rangeSlider.eq(i).attr('data-max'),
                from = rangeSlider.eq(i).attr('data-from'),
                to = rangeSlider.eq(i).attr('data-to');
            if( reset ) rangeSlider.eq(i).data("ionRangeSlider").reset()
            else
                rangeSlider.eq(i).ionRangeSlider({
                    type: "double",
                    min: min,
                    max: max,
                    from: from,
                    to: to,
                    from_percent: 0,
                    to_percent: 100

                });
        }

        $('.irs-slider').on('mousedown touchstart', function() {
            if ($(this).hasClass('to')) {
                $(this).siblings('.irs').find('.irs-from').removeClass('visible');
                $(this).siblings('.irs').find('.irs-to').addClass('visible');
            } else if ($(this).hasClass('from')) {
                $(this).siblings('.irs').find('.irs-to').removeClass('visible');
                $(this).siblings('.irs').find('.irs-from').addClass('visible');
            }
        });
    }
}


$(window).scroll(function(){
    if($(window).scrollTop() > 1000){
        $('.js-scroll-top').addClass('active');
    } else {
        $('.js-scroll-top').removeClass('active');
    }
});


// Entry popup
$('.entry-tab-reg').on('click', function() {
    $('.entry-log').removeClass('active');
    $('.entry-tab-log').removeClass('active');

    $(this).addClass('active');
    $('.entry-reg').addClass('active');
});

$('.entry-tab-log').on('click', function() {
    $('.entry-reg').removeClass('active');
    $('.entry-tab-reg').removeClass('active');

    $(this).addClass('active');
    $('.entry-log').addClass('active');
});

if( typeof $.fn.tooltipster != "undefined" )
    $('.tooltip-no-mobile').tooltipster({
        touchDevices: false
    });


// Desctop profile menu
$('.profile-menu-open').on('click', function(e) {
    e.preventDefault();
    $('.profile-menu').slideToggle();
});


// Change calspan in Personal Messages Table
$('.pmlist table tr:last-child td:first-child').attr('colspan', 1);


/**
 * Изменение размера текста (у меня, выезд, сопровождение), если он не вмещается в строку
 */
$(window).on(function () {
    if (window.innerWidth > 599) {
        let textElements = $('.product-features .catalog-item__tags li');
        textElements.each(function() {
            resizeToOriginalSize($(this))
        });
        resize_to_fit(textElements);
    }
});
$(function () {
    let textElements = $('.product-features .catalog-item__tags li');
    if (window.innerWidth > 599) {
        resize_to_fit(textElements);
    }

    let resising = false
    $(window).resize(function() {
        let textElements = $('.product-features .catalog-item__tags li');
        if (window.innerWidth > 599 && !resising) {
            textElements.each(function() {
                resizeToOriginalSize($(this))
            });
            resising = !resize_to_fit(textElements);
        } else {
            textElements.each(function() {
                resizeToOriginalSize($(this))
            });
            resising = false;
        }
    });
});
function resizeToOriginalSize(li) {
    let b = li.find("b")
    let svg = li.find(".icon")
    li.css('fontSize', 14);
    b.css('fontSize', 14);
    svg.css('height', 18);
}

function resize_to_fit(textElements) {
    let li = $('.product-features .catalog-item__tags li').eq(0);
    if (li.length > 0) {
        let fontsize = textElements.eq(0).css('font-size');
        // console.log(li);
        let {lineHeight} = getComputedStyle(li[0] );
        const lineHeightParsed = parseInt(lineHeight.split('px')[0]);
        const amountOfLinesTilAdjust = 2;

        if (li[0].offsetHeight >= 19 ) {
            textElements.each(function() {
                let b = $( this ).find("b")
                let svg = $( this ).find(".icon")
                $( this ).css('fontSize', parseFloat(fontsize) - 1);
                b.css('fontSize', parseFloat(fontsize) - 1);
                svg.css('height', parseFloat(fontsize) - 1);
            });
            resize_to_fit(textElements);
        }
    }
    return true;
}
