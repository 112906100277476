
export class ModalConfirmHtml {
    private app: app.App
    private modalElement: HTMLDivElement;
    private confirmButton: HTMLAnchorElement;
    private cancelButton: HTMLAnchorElement;
    private callback: Function | undefined;
    private dataCallback: object | undefined;

    constructor(app: app.App) {
        this.app = app
        this.modalElement = this.createModalElement();
        this.confirmButton = this.modalElement.querySelector('.confirm') as HTMLAnchorElement;
        this.confirmButton.addEventListener('click', this.handleConfirm.bind(this));
        this.cancelButton = this.modalElement.querySelector('.cancel') as HTMLAnchorElement;
        this.cancelButton.addEventListener('click', this.handleClose.bind(this));
        this.cancelButton = this.modalElement.querySelector('.mfp-close') as HTMLAnchorElement;
        this.cancelButton.addEventListener('click', this.handleClose.bind(this));
    }

    private createModalElement(): HTMLDivElement {
        const modalElement = document.createElement('div');
        modalElement.className = 'modal modal-center modal-large modal-confirm';
        modalElement.style.display = 'none';

        modalElement.innerHTML = `
            <div class="modal-wrap">
                <div class="modal-content">
                    <div class="modal-content-wrap">
                        <h3 class="modal-title"></h3>			
                        <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                        <div class="buttons">
                            <a href="#" class="button confirm">Да</a>
                            <a href="#" class="button cancel">нет</a>
                        </div>
                    </div>
                </div>
            </div>
        `;
        let main = document.body.querySelector('.main.page')
        if (main) {
            main.appendChild(modalElement);
        }

        return modalElement;
    }

    private handleConfirm(event: Event) {
        event.preventDefault();
        this.modalElement.style.display = 'none';
        if (this.callback) {
            this.callback(this.dataCallback);
        }
        this.handleClose(event)
    }

    private handleClose(event: Event) {
        event.preventDefault();
        this.modalElement.remove()
        this.app.modal?.closeOverlay()
    }

    public show(message: string, callback: Function, data: object) {
        let title = this.modalElement.querySelector('.modal-title');
        if (title) {
            title.textContent = message;
        }

        this.modalElement.style.display = 'block';
        this.callback = callback;
        this.dataCallback = data;
        this.app.modal?.showModal("confirm")
    }
}

