export class Search {
    app: app.App
    elems = {
        formSelect: $('.form.search .select'),
        formInput: $('.form.search input'),
        buttonSearch: $('.form.search .button-search'),
    }

    constructor(app: app.App) {
        this.app = app
        this.init()
    }

    init = () => {
        this.elems.formSelect.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()

            if (e.target.tagName !== "INPUT") {
                this.elems.formInput.focus()
            }
        })

        this.elems.buttonSearch.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()

            this.search()
        })

        this.elems.formInput.on('keyup', (e) => {
            e.preventDefault()
            e.stopPropagation()

            if (e.key === "Enter") {
                this.search()
            }
        })
    }

    search = () => {
        let search = this.getPhrase()
        if (!search) {
            // return
        }

        this.redirectToSearchPage()
    }

    getPhrase = () => {
        let phrase = this.elems.formInput.val()
        if (!phrase || phrase.toString().length === 0) {
            return null
        }
        return phrase.toString()
    }

    redirectToSearchPage = () => {
        const path = '/search';
        let query: searchObject = {}
        let phrase = this.getPhrase()
        if (phrase) {
            query.p = phrase
        }

        const queryString = Object.entries(query)
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value as string))
            .join('&');

        // Перенаправляем на новый URL
        window.location.href = path + '?' + queryString;
    }
}

type searchObject = {
    p?: string;
    page?: string;
};

