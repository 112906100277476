
export class Geo {
    app: app.App
    el: {
        buttonNear: JQuery|undefined
    }

    constructor(app: app.App) {
        this.app = app
        this.el = {
            buttonNear: $('.form .button-location')
        }
        this.init()
    }

    init = () => {
        this.addListeners()
    }

    addListeners = () => {
        this.el.buttonNear?.on('click', async (e) => {
            const data = $(e.currentTarget).data()
            if (data.catalog) {
                $(e.currentTarget).addClass('loading')
                await navigator.geolocation.getCurrentPosition(async (position) => {
                    console.log('geo positionSuccess', position)

                    const dataRequest: Cities.AjaxCatalog = {
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                        cityName: undefined
                    }
                    this.app.filters?.uncheckedFilters.resetAllFilters(true, true)
                    if (data.catalog === 'category') {
                        console.log('geo get category')
                        const result = await this.app.catalog?.catalogAjaxGeo(dataRequest)
                    } else if (data.catalog === 'city') {
                        const result = await this.app.catalogCity?.getCityAndCatalog(dataRequest, false)
                    }

                    $(e.currentTarget).removeClass('loading')
                }, (positionError) => {
                    console.log('geo positionError', positionError)
                    this.app.modal?.addModal({name: "geolocation"}, 3)
                    $(e.currentTarget).removeClass('loading')
                });
            }
        })
    }

     getCityGeo = async (geolocation: GeolocationCoordinates) => {
        return new Promise<app.GeoCity|null>((resolve, reject) => {
            const lat = geolocation.latitude
            const long = geolocation.longitude
            // @ts-ignore
            const link = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&types=(cities)&strictbounds=true&key=${window.googleMapsApi ?? ""}`

            $.ajax({
                headers: {
                    "Accept-Language": "de, at, at-AT"
                },
                url: link,
                data: {lat, long},
            })
                .done((data) => {
                    let subLocality: string|null = null, locality: string|null = null, country: string|null = null, parentName: string|null = null, name: string|null = null

                    if (data.results) {
                        console.log("maps results", data.results)
                        data.results.forEach((address: any) => {
                            if (address.types.length === 2 && address.types.includes('locality') && address.types.includes('political')) {
                                locality = address.place_id
                            } else if (address.types.length === 3 && address.types.includes('sublocality') && address.types.includes('political') && address.types.includes('sublocality_level_1')) {
                                subLocality = address.place_id
                            }
                            for (const addressComponentsKey in address.address_components) {
                                const val = address.address_components[addressComponentsKey]
                                if (!name && val.types && val.types.length === 2 && val.types.includes('locality') && val.types.includes('political')) {
                                    name = val.short_name
                                }
                                // if (!parentName && val.types && val.types.length === 3 && val.types.includes('sublocality_level_1') && val.types.includes('political') && val.types.includes('sublocality')) {
                                //     parentName = val.short_name
                                // }
                                if (!parentName && val.types && val.types.length === 2 && val.types.includes('administrative_area_level_1') && val.types.includes('political')) {
                                    parentName = val.short_name
                                }
                                if (!country && val.types && val.types.length === 2 && val.types.includes('country') && val.types.includes('political')) {
                                    country = val.short_name
                                }
                            }
                        })

                        const cityGeo = <app.GeoCity>{
                            place_id: subLocality ?? locality ?? null,
                            name: name,
                            parent_name: parentName,
                            lat: lat,
                            long: long,
                            country: country
                        }
                        resolve(cityGeo)
                    }
                    resolve(null)
                })
                .fail(() => {
                    resolve(null)
                })
        })
    }

    buttonNearSetActive = (active: boolean) => {
        if (active) {
            this.el.buttonNear?.addClass('checked')
        } else {
            this.el.buttonNear?.removeClass('checked')
        }
    }
}

