
export class Modal {
    app: app.App

    constructor(app: app.App) {
        this.app = app
        this.addListenersClosed()
    }

    addListenersClosed() {
        $('html').on('click', (e) => {
            if ($(e.currentTarget).hasClass('irs-line')) {
                if ($(e.target).hasClass('modal-wrap') || $(e.target).hasClass('modal-content')) {
                    console.log("close overlay");
                    $('html').removeClass('irs-line')
                    $('.overlay.opened').removeClass('opened')
                    $(e.target).parent().addClass('hidden')
                    $('.modal-large').remove()
                }
            }
        })
        $('.mfp-close').on('click', (e) => {
            $('html').removeClass('irs-line')
            $('.overlay.opened').removeClass('opened')
            $(e.target).parents('.modal').addClass('hidden')
        })
    }


    addListeners = (modal: Enums.Modal) => {
        this.getModalByName(modal).find('.close').on('click', (e) => {
            this.removeModal(modal)
        })
    }

    addModal = (modal: Enums.Modal, showSeconds: number, addText: string|null = null) => {
        this.removeModal(modal)
        let text = ""
        switch (modal.name) {
            case "geolocation" : {
                text = "Вы заблокировали определение своей геолокации"
                break
            }
            case "notFoundWorksheets" : {
                text = "К сожалению, ваш город "+addText+" находится не в Австрии. Выбери вручную нужный вам город на сайте для поиска объявлений"
                break
            }
            case "noCity" : {
                text = "Не смогли определить город"
                break
            }
            case "noAuth" : {
                text = "Вам нужно авторизоваться, чтобы добавлять анкеты в список желаемых </br> <a href='/login'>Авторизоваться</a>"
                break
            }
            case "error" : {
                text = "Ошибка: " + addText
                break
            }
            default: {
                return
            }
        }
        const html = "<div class='modal modal-" + modal.name + "' data-name='"+ modal.name +"' style=''><div class='text'>" + text + "</div><div class='close'>Закрыть</div></div>"
        $('body').append(html)

        this.getModalByName(modal).fadeIn().addClass('active')
        this.addListeners(modal)
        if (showSeconds) {
            this.addCloseByTimer(modal, showSeconds)
        }
    }

    showModal = (modalName: string) => {
        $('html').addClass('irs-line')
        $('.overlay').addClass('opened')
        $('.modal.modal-' + modalName).removeClass('hidden')
    }

    closeOverlay = () => {
        $('html').removeClass('irs-line')
        $('.overlay').removeClass('opened')
    }

    addCloseByTimer = (modal: Enums.Modal, showSeconds: number) => {
        setInterval(() => {
            this.removeModal(modal)
        }, showSeconds * 1000)
        this.getModalByName(modal)
    }

    removeModal = (modal: Enums.Modal) => {
        this.getModalByName(modal)?.fadeOut(300, () => {
            this.getModalByName(modal).remove()
        })
    }

    getModalByName = (modal: Enums.Modal): JQuery => {
        return $('.modal[data-name="'+ modal.name +'"]')
    }
}

