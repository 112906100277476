export class Footer {
    app: app.App

    elFooter:JQuery<HTMLElement> | undefined
    elButtons:JQuery<HTMLElement> | undefined

    footerQuery = {
        footer: 'footer.footer',
        buttons: '.buttons',
    }

    constructor(app: app.App) {
        this.app = app
        this.refreshElements()
        this.addScrollByDrag()
    }

    refreshElements = () => {
        this.elFooter = $(this.footerQuery.footer)
        this.elButtons = this.elFooter.find(this.footerQuery.buttons)
    }

    addScrollByDrag = () => {
        const scrollELem = this.elButtons?.find('.scroll')
        console.log('scrollELem', scrollELem)
        if (scrollELem && scrollELem.length > 0) {
            this.app.services.scrollByDrag(scrollELem[0])
        }
    }
}
