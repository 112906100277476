import {getAjaxHeader} from "../functions";
import {AbuseSave, ModalAbuse} from "../modal/modal-abuse";
import {ModalAbuseHtml} from "@/js/modal/modal-abuse-html";

export class Abuse {
    app: app.App
    elQuery = {
        abuseAdd: '.abuse-title[data-type="worksheet"]',
    }

    constructor(app: app.App) {
        this.app = app
        this.addListeners()
    }

    addListeners = () => {
        $(this.elQuery.abuseAdd).on('click', (e) => {
            e.preventDefault()
            let data = $(e.currentTarget).data()
            let modalAbuse = new ModalAbuse(this.app)
            let name = $('.page__title.product-name').first().text()
            modalAbuse.show(name, this.addAbuse, <AbuseSave>{abuseId: null, worksheetId: data.id, formData: ""})
        })
    }

    addAbuse = async (data: AbuseSave, instance: ModalAbuseHtml) => {
        return new Promise<void>((resolve, reject) => {
            let $this = this

            $.ajax({
                url: "/api/abuse/worksheet/add/" + data.worksheetId,
                type: 'POST',
                headers: getAjaxHeader(), // Добавляем заголовок
                data: data.formData,
                success: function(response) {
                    console.log('form add review success', response, instance)
                    instance.send()
                },
                error: function(xhr, status, error) {
                    if (xhr.status === 401) {
                        $this.app.modal?.addModal({name: "noAuth"}, 99)
                    }
                }
            });
        })
    }
}

