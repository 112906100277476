$(document).ready(function () {
    async function openedGallery() {
        let arrImages = [];
        $('a[data-fancybox="product-gallery"]').each((index, a) => {
            arrImages.push(a.href)
        })
        for (const link of arrImages) {
            await lazyLoadImage(link)
        }
    }
    async function lazyLoadImage (src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = function () {
                resolve(true)
            }
        })
    }
    function addListenerOpenGallery () {
        $('a[data-fancybox="product-gallery"]').on('click', (e) => {
            openedGallery()
        })
    }
    addListenerOpenGallery()
})
