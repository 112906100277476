import { HistoryCatalog } from './historyCatalog';
import { HistoryRegion } from './historyRegion';

export class History {
    app: app.App

    constructor(app: app.App) {
        this.app = app;
    }

    addListenerPopstate = () => {
        const $this = this
        $(window).on('popstate', function(event) {
            console.log('return back', window.history, event.originalEvent)
            if (window.history.state) {
                if (window.history.state.isBackPage) {
                    window.history.go(-1);
                }
                let historyRegion = new HistoryRegion()
                if (historyRegion.instanceOfHistoryRegion(window.history.state)) {
                    historyRegion.createByState(window.history.state)
                    location.reload()
                    // if (!historyRegion.isNull()) {
                    //     $this.app.catalogCity?.windowBack(window.history.state)
                    // } else {
                    //     // window.history.back();
                    //     location.reload()
                    // }
                } else if (new HistoryCatalog().instanceOfHistoryCatalog(window.history.state)) {
                    $this.app.catalog?.windowBack(window.history.state)
                } else {
                    console.log('history.back no region/catalog', history)
                    // @ts-ignore
                    location.reload()
                }
            } else {
                console.log('history.back no state', window.history.state)
                if (window.location.hash === '#more') {
                    history.replaceState({isBackPage: true}, "", window.location.origin + window.location.pathname)
                }
                // @ts-ignore
                // location.reload()
            }
        });
        // window.addEventListener('hashchange', function() {
        //     console.log('hashchange', event)
        // }, false);
        // const doThisWhenTheHashChanges = (event: any) => {
        //     console.log('hashchange', event)
        // }
        // window.addEventListener("hashchange", doThisWhenTheHashChanges, false);
    }

    addCurrentPageToHistory = () => {
        if (window.location.hash === '#more') {
            return this
        }
        console.log('addCurrentPageToHistory history.state', history.state)

        let historyData = null
        if (history.state) {
            console.log('addCurrentPageToHistory no added')
            // history.replaceState({}, "", window.location.href)
            return this
        }
        if (this.app.isCategory() && this.app.catalog) {
            historyData = new HistoryCatalog().create(this.app.catalog).getState()
        } else if (this.app.isHomeOrCity()) {
            if (this.app.currentRoute.city?.id === -1) {
                historyData = new HistoryRegion().createByDataRequest({lat: this.app.currentRoute.city.lat, long: this.app.currentRoute.city.long, cityName: undefined}, 0).getState()
            } else {
                historyData = new HistoryRegion().createByDataRequest({lat: undefined, long: undefined, cityName: this.app.getCurrentCityUrlName()}, 0).getState()
            }
        }
        if (historyData) {
            window.history.replaceState(historyData, '', window.location.href)
            console.log(`window pushstate: ${window.location.href}, historyData: ,`, historyData, `, app: `, this.app);
        }
        return this
    }
}
