// @ts-ignore
import {deleteCookie, setCookie} from './functions'

export class Location {
    app: app.App
    modalGeo = {
        icon: $('.header-location > .city'),
        iconMobile: $('.nav-mobile__head > .city'),
        selectCity: $('.select-city > .select'),
        selectCategory: $('.select-category > .select'),
        selectRadius: $('.select > .radius'),
        modal: $('.modal-menu'),
        modalCityColumn: $('.modal-menu .geolocation-city'),
        modalSubCityColumn: $('.modal-menu .geolocation-subcity'),
        city: $('.modal-menu.city-form .geolocation-city, .modal-menu.city-header .geolocation-city'),
        subcity: $('.modal-menu.city-form .geolocation-subcity, .modal-menu.city-header .geolocation-subcity'),
        cityName: $('.header-location > .city > span, .nav-mobile__head > .city > span, .header-form .select-city .text'),
        deleteCookie: $('.delete-cookie'),
    }

    constructor(app: app.App) {
        this.app = app
        this.init()
    }

    init = () => {
        this.modalGeo.modalCityColumn.find('li').each((index, li) => {
            $(li).on('click', (e) => {
                e.preventDefault()
                const currentModal = $(e.currentTarget).parents('.modal-menu')
                $(e.currentTarget).parents('.geolocation-city').find('li.active').removeClass('active')
                $(e.currentTarget).addClass('active')
                currentModal.find('.cities-columns').each((index, ul) => {
                    $(ul)?.removeClass('active')
                })
                currentModal.find(".cities-columns[data-id='" + $(e.currentTarget)[0].dataset.id + "']").addClass('active')
                // this.resetActiveSubCity()
            })
        })

        this.modalGeo.modalSubCityColumn.find('li').each((index, li) => {
            $(li).on('click', async (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                const dataset = $(e.currentTarget)[0].dataset;
                $(e.currentTarget).parents('.modal-menu').slideToggle(100).toggleClass('opened')

                const regionId: string = $(e.currentTarget).parents('.cities-columns').data().id
                const region: string = dataset.regionLink ?? ""
                const city: string = dataset.cityLink ?? ""
                this.app.catalog?.form.changeCity(region, city)
                if (dataset.city) {
                    this.setCity(dataset.city, regionId)
                    // this.modalGeo.cityName.each((i, text) => {
                    //     // @ts-ignore
                    //     $(text).text(dataset.name ? dataset.parentname + ", " + dataset.name : dataset.parentname)
                    // })
                }
                if (this.app.isHomeOrCity()) {
                    await this.app.catalogCity?.getCityAndCatalog({cityName: city.length > 0 ? city : region, long: undefined, lat: undefined}, false)
                    // this.changeCityInSelect(dataset.parentname ?? null, dataset.name ?? null, parseInt(dataset.regionInUrl ?? "") === 1 ?? false, false)
                } else {
                    console.log('dataset', dataset)
                    console.log('Boolean(dataset.inUrl)', Boolean(dataset.regionInUrl))
                    await this.app.catalog?.catalogAjax(false, false, true)
                    this.changeCityInSelect(dataset.parentname ?? null, dataset.name ?? null, parseInt(dataset.regionInUrl ?? "") === 1 ?? false, false)
                }

            })
        })

        this.modalGeo.icon.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            const classModal = $(e.currentTarget).data().modal
            $('.modal-menu.' + classModal).slideToggle(100).toggleClass('opened')
            this.closeModalsWithout(classModal)
        })

        this.modalGeo.iconMobile.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            const classModal = $(e.currentTarget).data().modal
            $('.modal-menu.' + classModal).slideToggle(100).toggleClass('opened')
            this.closeModalsWithout(classModal)
        })

        this.modalGeo.selectCity.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            const classModal = $(e.currentTarget).data().modal
            $('.modal-menu.' + classModal).slideToggle(100).toggleClass('opened')
            this.closeModalsWithout(classModal)
        })

        this.modalGeo.selectRadius.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            if ($(e.currentTarget).hasClass('disabled')) {
                return
            }
            const classModal = $(e.currentTarget).data().modal
            $('.modal-menu.' + classModal).slideToggle(100).parent().toggleClass('opened')
            this.closeModalsWithout(classModal)
        })

        this.modalGeo.selectCategory.on('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!$(e.currentTarget).parent().hasClass('disabled')) {
                const classModal = $(e.currentTarget).data().modal
                $('.modal-menu.' + classModal).slideToggle(100).toggleClass('opened')
                this.closeModalsWithout(classModal)
            }
        })

        this.modalGeo.deleteCookie.on('click', async (e) => {
            e.preventDefault()
            e.stopPropagation()
            console.log('убрать город this.app.currentPage=', this.app.currentPage)
            deleteCookie('city', '/');
            deleteCookie('geoCity', '/');

            this.closeModalsAll()
            this.app.catalog?.form.changeCity("", "")
            if (this.app.isHomeOrCity()) {
                await this.app.catalogCity?.setHome(false)
            } else {
                await this.app.catalog?.catalogAjax(false, false, true)
            }
            this.changeCityInSelect(null, null, false, false)
            this.resetActiveSubCity()
        })
    }

    setCity = (id: string, regionId: string) => {
        setCookie('city', id, {expires: 30 * 24 * 60 * 60, path: '/'})
        this.modalGeo.modalSubCityColumn.each((i, cityColumn) => {
            $(cityColumn).find('.cities-columns').removeClass('active current')
            $(cityColumn).find('.cities-columns[data-id="'+regionId+'"]').addClass('active current')
            $(cityColumn).find('li.active').removeClass('active')
            $(cityColumn).find('li.current').removeClass('current')
            $(cityColumn).find('li[data-city="'+id+'"]').addClass('active current')
        })
        this.modalGeo.modalCityColumn.each((i, cityColumn) => {
            $(cityColumn).find('li.active').removeClass('active')
            $(cityColumn).find('li.current').removeClass('current')
            $(cityColumn).find('li[data-id="'+regionId+'"]').addClass('active current')
        })

        this.app.geo?.buttonNearSetActive(false)
        this.app.header?.closeModalMenu()
    }
    setCityFromGeo = (geoCity: app.GeoCity) => {
        console.log('setCityFromGeo', geoCity)
        this.app.geo?.buttonNearSetActive(geoCity.cityId === -1)
        this.modalGeo.cityName.each((i, text) => {
            // @ts-ignore
            $(text).text(geoCity.name_translate ? geoCity.name_translate : geoCity.parent_name_translate)
        })

        this.modalGeo.city.find('li').removeClass('active')
        const findCity = this.modalGeo.city.find('li[data-id="'+(geoCity.cityId == 0 ? geoCity.subCityId : geoCity.cityId)+'"]')
        const subCityColumns = this.modalGeo.subcity.find('.cities-columns[data-id="'+(geoCity.cityId == 0 ? geoCity.subCityId : geoCity.cityId)+'"]')
        this.modalGeo.subcity.find('.active').removeClass('active')

        if (findCity.length > 0) {
            findCity.addClass('active')
            subCityColumns.addClass('active')
        } else {
            // const findRemoveCity = this.modalGeo.city.find('ul li[data-id="0"]')
            // if (findRemoveCity.length > 0) {
            //     findRemoveCity.before("<li data-id='"+geoCity.cityId+"' class='active'><a href='#'>"+geoCity.parent_name_translate+"</a></li>")
            // } else {
            //     this.modalGeo.city.find('ul').append("<li data-id='"+geoCity.cityId+"' class='active'><a href='#'>"+geoCity.parent_name_translate+"</a></li>")
            // }
        }
        const findSubCity = this.modalGeo.subcity.find('li[data-city="'+geoCity.subCityId+'"]')

        if (findSubCity.length > 0) {
            findSubCity.addClass('active')
        } else {
            // if (subCityColumns.length > 0) {
            //     subCityColumns.find('ul').append("<li data-city='"+geoCity.cityId+"' class='active'><a href='#'>"+geoCity.name_translate+"</a></li>")
            // } else {
            //     this.modalGeo.subcity.append("<div class='cities-columns active' data-id='-1'><ul><li data-city='"+geoCity.cityId+"' class='active'><a href='#'>"+geoCity.name_translate+"</a></li></ul></div>")
            // }
        }
    }

    resetActiveSubCity = () => {
        this.modalGeo.subcity.find('ul').each((index, ul) => {
            $(ul).find('li').each((index, li) => {
                $(li).removeClass('active')
            })
            $(ul).find('.current.active').each((index, div) => {
                $(div).removeClass('current').removeClass('active')
            })
        })
        this.modalGeo.city.find('ul').each((index, ul) => {
            $(ul).find('.current.active').each((index, div) => {
                $(div).removeClass('current').removeClass('active')
            })
        })
        $(this.modalGeo.subcity.find('ul.active').find('li')[0]).addClass('active')
    }

    selectCategoryRemoveDisabled = () => {
        this.modalGeo.selectCategory.parent().removeClass('disabled')
    }

    closeModalsWithout = (classWithout: string) => {
        $('.modal-menu').each((index, elModal) => {
            if (!$(elModal).hasClass(classWithout) && $(elModal).hasClass('opened')) {
                $(elModal).slideToggle(100, () => {
                    $(elModal).find('.active:not([data-radius])').removeClass('active')
                    $(elModal).find('.current').addClass('active')
                }).toggleClass('opened')
            }
        })
        this.app.filters?.closeOpenedDropDowns(false)
    }

    closeModalsAll = () => {
        $('.modal-menu').each((index, elModal) => {
            if ($(elModal).hasClass('opened')) {
                $(elModal).slideToggle(100).toggleClass('opened')
            }
        })

        const navToggle = $('.js-nav-mobile-trigger')
        const navMobile = $('.js-nav-mobile')
        const overlay = $('.overlay')
        if (navMobile.length) {
            if (navMobile.hasClass('opened')) {
                navToggle.toggleClass('active');
                navMobile.toggleClass('opened');
                overlay.toggleClass('opened');
            }
        }
    }

    changeCityInSelect = (region: string|null, city: string|null, regionInUrl: boolean, showRegionAlways: boolean) => {
        console.log('changeCityInSelect', regionInUrl, showRegionAlways)
        city = city?.length === 0 ? null : city
        this.modalGeo.cityName.each((i, text) => {
            if (region || city) {
                const cityOrRegion = city ?? region ?? ""
                if (showRegionAlways || regionInUrl) {
                    $(text).text(city && region ? region + ", " + city : cityOrRegion)
                } else {
                    $(text).text(cityOrRegion)
                }
            } else {
                $(text).text("Укажите город")
            }
        })
    }
}


