import '../main';
import '../gallery';

import { Location } from '../location';
import { Catalog } from '../catalog/catalog';
import { Filters } from '../catalog/filters/filters';
import { getKeyFromSearch } from '../functions';
import { CatalogCity } from "../catalog/catalog-city";
import { scrollByDrag } from "../services/scrollByDrag";
import { Footer } from "../footer";
import { Header } from "../header/header";
import { Geo } from "../geo/geo";
import { Modal } from "../modal/modal";
import { History } from '../history/history';
import {Wishlist} from "../wishlist/wishlist";
import {Reviews} from "../reviews/reviews";
import {ModalConfirm} from "../modal/modal-confirm";
import {UserPanel} from "../user-panel/user-panel";
import {ClickTracking} from "../analytics/click-tracking";
import {Abuse} from "../abuse/abuse";
import {Search} from "../catalog/search";

export class App {
    isCopy: boolean = false
    geo: Geo
    location: Location
    catalog: Catalog
    catalogCity: CatalogCity
    filters: Filters
    footer: Footer
    header: Header
    modal: Modal
    modalConfirm: ModalConfirm
    wishList: Wishlist
    reviews: Reviews
    abuse: Abuse
    search: Search
    clickTracking: ClickTracking

    userPanel: UserPanel

    currentRoute: app.CurrentRoute
    currentPage: string
    services = {
        scrollByDrag
    }

    constructor(currentRoute: app.CurrentRoute, currentPage: string) {
        this.currentRoute = currentRoute
        this.currentPage = currentPage
        this.geo = new Geo(this)
        this.location = new Location(this)
        this.catalog = new Catalog($('body').first(), this)

        this.catalog.refreshElements()
        this.catalog.addListenerCards()
        this.catalog.addListenerPagination()
        this.catalog.lazyImages(this.catalog.elImages)
        this.catalog.getParams()
        this.catalog.addListenerSorting()

        this.catalogCity = new CatalogCity(this)
        this.filters = new Filters(this)
        this.footer = new Footer(this)
        this.header = new Header(this)
        this.modal = new Modal(this)
        this.modalConfirm = new ModalConfirm(this)
        this.wishList = new Wishlist(this)
        this.reviews = new Reviews(this)
        this.abuse = new Abuse(this)
        this.search = new Search(this)
        this.clickTracking = new ClickTracking(this)

        this.userPanel = new UserPanel(this)

        this.catalog.syncFiltersAfterLoad()
    }

    setCurrentPage = (pageName: string) => {
        this.currentPage = pageName
    }

    isHomeOrCity = (): boolean => {
        return this.currentPage === 'region' || this.currentPage === 'home'
    }

    isCategory = (): boolean => {
        return this.currentPage === 'category'
    }

    getCurrentCityUrlName = (): string|null => {
        if (this.currentRoute.city) {
            return this.currentRoute.city.name
        } else if (this.currentRoute.region) {
            return this.currentRoute.region.name
        }
        return null
    }
}

$(document).ready(function (this: any) {
    // @ts-ignore currentRoute - window
    const app = new App(window.currentRoute, window.currentPage)

    const distance = getKeyFromSearch('umkreis')
    if (distance) {
        app.catalog.form.distance = parseInt(distance)
        app.catalog.form.formTopFilters.setRadius(distance)
    }
    // window.addEventListener('click', function(e){
    //     console.log('try close modals')
    //     // @ts-ignore
    //     if (app.location?.modalGeo.modal.has(e.target).length === 0) {
    //         app.location.modalGeo.modal.each((index, modal) => {
    //            if ($(modal).hasClass('opened'))  {
    //                $(modal).slideToggle(100, () => {
    //                    $(modal).find('.active:not([data-radius])').removeClass('active')
    //                    $(modal).find('.current').addClass('active')
    //                }).toggleClass('opened')
    //             }
    //         })
    //         console.log('modal')
    //     } else {
    //         console.log('not modal')
    //     }
    //     app.filters?.closeOpenedDropDowns(false)
    //     app.catalog?.form.formTopFilters?.closeOpenedDropDowns(e)
    // });

    window.addEventListener("resize", resizeWindow);
    window.addEventListener("scroll", scrollWindow);

    function resizeWindow() {
        app.catalog.updatePositionSeoShortMore()
    }

    function scrollWindow() {
        if (app.filters.elGroup && app.filters.elGroup.length > 0) {
            if (app.filters.elGroup[0].getBoundingClientRect().top < 0) {
                if (app.filters.filtersGroupIsVisible()) {
                    app.filters.closeFiltersGroup()
                }
            }
        }
    }

    $('html').on('click', (e) => {
        $(e).removeClass('irs-line')
    })

    // @ts-ignore
    window["app"] = app

    new History(app).addCurrentPageToHistory().addListenerPopstate()
})
