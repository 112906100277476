import {AbuseSave} from "@/js/modal/modal-abuse";

export class ModalAbuseHtml {
    private app: app.App
    private modalElement: HTMLDivElement;
    private cancelButton: HTMLAnchorElement;
    private titleElement: JQuery<HTMLElement>;
    private form: JQuery<HTMLElement>;
    private messageTextarea: JQuery<HTMLElement>;
    private submitButton: JQuery<HTMLElement>;
    private successText: JQuery<HTMLElement>;
    private callback: Function | undefined;
    private dataCallback: AbuseSave | undefined;

    constructor(app: app.App) {
        this.app = app
        this.modalElement = this.createModalElement();
        this.cancelButton = this.modalElement.querySelector('.mfp-close') as HTMLAnchorElement;
        this.cancelButton.addEventListener('click', this.handleClose.bind(this));

        this.titleElement = $(this.modalElement).find('.modal-title');
        this.messageTextarea = $(this.modalElement).find('textarea[name="message"]');
        this.submitButton = $(this.modalElement).find('input[type="submit"]');
        this.form = $(this.modalElement).find('form');
        this.successText = $(this.modalElement).find('.success');
        this.submitButton.on('click', this.handleSubmit)
    }

    private createModalElement(): HTMLDivElement {
        const modalElement = document.createElement('div');
        modalElement.className = 'modal modal-center modal-large modal-abuse';
        modalElement.style.display = 'none';

        modalElement.innerHTML = `
            <div class="modal-wrap">
                <div class="modal-content">
                    <div class="modal-content-wrap">
                        <h3 class="modal-title"></h3>	
                        <form>
                            <div class="abuse-row">            
                                <div>
                                    <label for="reviewText">Ваше сообщение</label>
                                    <textarea id="reviewText" name="message" required=""></textarea>
                                    <p class="error hidden">Нужно добавить сообщение к отзыву</p>
                                </div>
                            </div>
                            <div class="review-popup-submit">
                                <input type="submit" value="Отправить" class="main-button">
                            </div>
                        </form>	
                        <div class="hidden success">Ваша жалоба отправлена. Рассмотрим её в ближайшее время.</div>	
                        <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                    </div>
                </div>
            </div>
        `;
        let main = document.body.querySelector('.main.page')
        if (main) {
            main.appendChild(modalElement);
        }

        return modalElement;
    }

    private handleClose(event: Event) {
        event.preventDefault();
        this.modalElement.remove()
        this.app.modal?.closeOverlay()
    }

    handleSubmit = (e: Event) => {
        e.preventDefault()
        let formData = $(this.modalElement).find('form').serialize()
        if (!this.checkFormError()) {
            return
        }
        if (this.callback && this.dataCallback) {
            this.dataCallback.formData = formData
            this.callback(this.dataCallback, this);
        }
    }
    public checkFormError() {
        let message = this.messageTextarea.val()
        if (typeof message === "string") {
            if (message.length === 0) {
                this.messageTextarea.next().removeClass('hidden')
                return false
            } else {
                this.messageTextarea.next().addClass('hidden')
            }
        }
        return true
    }

    public changeTitle(title: string) {
        this.titleElement.text(title)
    }

    public show(callback: Function, dataCallback: AbuseSave) {
        this.modalElement.style.display = 'block';
        this.callback = callback;
        this.dataCallback = dataCallback;
        this.app.modal?.showModal("review")
    }

    public send() {
        this.form.addClass('hidden')
        this.successText.removeClass('hidden')
    }
}

