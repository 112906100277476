import {ModalAbuseHtml} from "../modal/modal-abuse-html";

export class ModalAbuse {
    app: app.App

    constructor(app: app.App) {
        this.app = app
    }

    show = (worksheetName: string, callback: Function, data: AbuseSave) => {
        let modalAbuse = new ModalAbuseHtml(this.app)
        modalAbuse.changeTitle("Пожаловаться на " + worksheetName)
        modalAbuse.show(callback, data)
    }

}

export interface AbuseData {
    worksheetId: number
    worksheetName: string
    message: string
}
export interface AbuseSave {
    abuseId?: number|null
    worksheetId: number
    formData: string
}
