import {getAjaxHeader} from "../functions";
import {ModalReview, ReviewData, ReviewSave} from "../modal/modal-review";
import {UserPanelReviews} from "@/js/user-panel/user-panel-reviews";

export class ClickTracking {
    app: app.App
    elQuery = {
        link: 'a[data-type][data-id][data-is-salon]',
    }

    constructor(app: app.App) {
        this.app = app
        this.addListeners()
    }

    addListeners = () => {
        $(this.elQuery.link).on('click', (e) => {
            let data = $(e.currentTarget).data()
            this.add(data)
        })
    }

    add = async (data: object) => {
        return new Promise<void>((resolve, reject) => {
            let $this = this

            $.ajax({
                url: "/api/analytics/add",
                type: 'POST',
                headers: getAjaxHeader(), // Добавляем заголовок
                data: data,
                success: function(response) {
                    console.log('click tracking success', response)
                    // location.reload()
                },
                error: function(xhr, status, error) {
                    if (xhr.status === 401) {
                        $this.app.modal?.addModal({name: "noAuth"}, 99)
                    }
                }
            });
        })
    }
}

