import { arrayRemoveKey } from '../functions';

export class FormFilters {
    filters: filter = {}

    addFilter = (filterName: string, id: number|string) => {
        if (filterName === 'ages_from_to') {
            this.filters[filterName] = [id]
        } else if (filterName === 'weight_from_to') {
            this.filters[filterName] = [id]
        } else if (filterName === 'height_from_to') {
            this.filters[filterName] = [id]
        } else if (filterName === 'distance') {
            this.filters[filterName] = [id]
        } else if (this.filters.hasOwnProperty(filterName)) {
            if (!this.filters[filterName].includes(id)) {
                this.filters[filterName].push(id)
            }
        } else {
            this.filters[filterName] = [id]
        }
    }

    removeFilter = (filterName: string, id: number|any[]) => {
        if (this.filters.hasOwnProperty(filterName)) {
            if (Array.isArray(id)) {
                if (id[0] !== -1 || id[1] !== -1) {
                    this.filters[filterName] = [id.toString()]
                } else {
                    delete this.filters[filterName]
                }
            } else if (this.filters[filterName].includes(id)) {
                this.filters[filterName] = arrayRemoveKey(this.filters[filterName], id)
            }
        }
    }

    removeFilters = (filterName: string, removeCategories: boolean) => {
        console.log('filterName', filterName)
        if (filterName === 'all-filters') {
            const oldFilters = {...this.filters}
            this.filters = {}
            if (!removeCategories && oldFilters['categories']) {
                this.filters.categories = oldFilters['categories']
            }
        } else if (this.filters.hasOwnProperty(filterName)) {
            delete this.filters[filterName];
        }
    }

    getCountActiveFilters = (): number => {
        let countFilters = 0
        for (const i in this.filters) {
            if (i === 'categories') continue
            const filterArr = this.filters[i]
            if (filterArr && filterArr.length > 0) {
                if (filterArr[0] && typeof filterArr[0] === "string") {
                    const arrSplit = filterArr[0].split(",")
                    // @ts-ignore
                    if (arrSplit.length === 2 && arrSplit[0] > -1 && arrSplit[1] > -1 && arrSplit[0] !== arrSplit[1]) {
                        countFilters += 1
                    } else {
                        countFilters += filterArr.length
                    }
                } else {
                    countFilters += filterArr.length
                }
            }
        }
        // if (arrActiveFilters.includes('boobsSize') && arrActiveFilters.includes('breasts')) {
        //     arrActiveFilters.splice(arrActiveFilters.indexOf('boobsSize'), 1)
        // }
        return countFilters
    }
}

export interface filter {
    [key:string]: any[]
}
