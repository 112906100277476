import { FormFilters } from '../catalog/form-filters';
import { FormTopFilters } from '../catalog/form-top-filters';

export class Form {
    app: app.App
    sorting: number = 1
    page: number = 1
    city: string = ""
    lat: number|null = null
    long: number|null = null
    region: string = ""
    service: string = ""
    distance: number = 0
    cityFromCookie: boolean = false
    isChangeRadiusByButton: boolean = false

    formTopFilters: FormTopFilters
    // elHeader:JQuery<HTMLElement> | undefined

    constructor(app: app.App, $this: any) {
        this.app = app
        this.formTopFilters = new FormTopFilters(app, $this)
        this.addListeners()
    }

    refreshElements = () => {

    }

    addListeners = () => {

    }

    changeCity = (region: string, city: string) => {
        this.region = region
        this.city = city

        this.app.catalog?.form.formTopFilters?.setCurrentRadius(-1)
        this.app.catalog?.setDistance(-1)
        this.app.catalog?.setPage(1)

        // this.app.filters?.uncheckedFilters.resetAllFilters(true, true)
    }
}
