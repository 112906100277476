import { filter } from '../catalog/form-filters';
import { Catalog } from '@/js/catalog/catalog';

export class HistoryCatalog {
    sorting: number = 1
    page: number = 0
    city: string = ""
    region: string = ""
    lat: number|null = null
    long: number|null = null
    service: string = ""
    distance: number = -1
    url: string = ""
    filters: filter = {}

    create = (catalog: Catalog) => {
        this.sorting = catalog.form.sorting
        this.page = catalog.form.page
        this.city = catalog.form.city
        this.region = catalog.form.region
        this.lat = catalog.form.lat
        this.long = catalog.form.long
        this.service = catalog.form.service
        this.distance = catalog?.form.formTopFilters?.currentRadius ?? -1
        this.url = window.location.href
        this.filters = catalog.formFilters.filters
        return this
    }

    getState = () => {
        return {
            sorting: this.sorting,
            page: this.page,
            city: this.city,
            region: this.region,
            lat: this.lat,
            long: this.long,
            service: this.service,
            distance: this.distance,
            url: this.url,
            filters: this.filters,
        }
    }

    instanceOfHistoryCatalog = (object: any): object is HistoryCatalog => {
        return 'distance' in object && 'page' in object;
    }
}
