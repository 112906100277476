import {getAjaxHeader} from "../functions";

export class Wishlist {
    app: app.App
    elQuery = {
        wishProduct: '.product-favorite',
    }

    constructor(app: app.App) {
        this.app = app
        this.addListeners()
    }

    addListeners = () => {
        $(this.elQuery.wishProduct).on('click', (e) => {
            e.preventDefault()
            let worksheetId = $(e.currentTarget).data().id
            if ($(e.currentTarget).find('.icon-heart-on').length > 0) {
                $(this.elQuery.wishProduct).find('.icon-heart-on')
                    .addClass('icon-heart')
                    .removeClass('icon-heart-on')
                this.removeWish(worksheetId)
            } else {
                $(this.elQuery.wishProduct).find('.icon-heart').each((index, element) => {
                    $(element).addClass('icon-heart-on')
                        .removeClass('icon-heart')
                })
                this.addWish(worksheetId)
            }
        })
    }

    addWish = async (id: number) => {
        return new Promise<void>((resolve, reject) => {
            let $this = this

            $.ajax({
                url: "/api/wishlist/add/" + id,
                type: 'POST',
                headers: getAjaxHeader(), // Добавляем заголовок
                data: {
                },
                success: function(response) {
                    $this.responseCheck(response)
                },
                error: function(xhr, status, error) {
                    if (xhr.status === 401) {
                        $this.app.modal?.addModal({name: "noAuth"}, 99)
                    }
                }
            });
        })
    }

    removeWish = async (id: number) => {
        return new Promise<void>((resolve, reject) => {
            let $this = this

            $.ajax({
                url: "/api/wishlist/remove/" + id,
                type: 'POST',
                headers: getAjaxHeader(), // Добавляем заголовок
                data: {
                },
                success: function(response) {
                    $this.responseCheck(response)
                },
                error: function(xhr, status, error) {
                }
            });

        })
    }

    responseCheck = (resp: string) => {
        console.log('responseCheck', resp)
    }
}

